@import "../../variables.scss";

#footer {
  padding: 2em 0;
  border-top: solid 1px $lighter-grey;

  .text {
    font-family: 'Montserrat', sans-serif;
    padding: 0.5em 0;
  }

  .title {
    font-size: 16px;
    color: $primary-color;
    font-weight: 500;
  }

  .link {
    font-size: 14px;
    color: $black-text-color;
    font-weight: 300;
    line-height: 2em;
  }

  .logos-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;

  }

  .facebook-icon {
    margin-top: 1em;
    height: 1.7em;
  }
  
  .instagram-icon {
    margin-top: 1em;
    height: 1.7em;
  }

  .divider {
    border-top: solid 1px #e7e7e7;
  }

  .menus-container {
    margin-bottom: 2em;
  }

  .nosh-logo {
    width: 15vw;
    max-width: 210px;
    height: auto;
  }

  .payment-logos-column {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
  }

  .payment-logos {
    height: 1.7em;
    max-width: 300px;
    margin: 1em auto 60px auto;
    text-align: center;
  }

  .mobile-social-media-and-nosh-container {
    display: none;
  }


}


@media(max-width: 768px) {
  .mobile-social-media-and-nosh-container {
    padding: 1em;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    .mobile-social-media-logos-container {
      img {
        margin-left: 1em;
      }
    }
    img.mobile-nosh-logo {
      width: 6em;
      height: auto;
    }
    img {
      height: 2em;
      width: auto;
    }
  }
}