@import "../../../variables.scss";

#auth-actions-container {
  .title {
    font-size: 1.6em;
    text-align: center;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 600;
    margin-top: 2em;
    margin-bottom: 1em;
  }

  .btn {
    padding: 0.5em 4em;
    border: none;
    border-radius: $border-radius;
    background-color: $primary-color;
    color: white;
    height: 52px;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px;
    width: 100%;
  }
}
.forget-password-form{
  label{
    display:block;
    font-weight: normal;
  }
  input{
    display:block;
    width:100%;
    border: 1px solid #ccc;
    margin-bottom:2rem;
    height: 34px;
  }
}