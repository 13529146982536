@import "../../variables.scss";

.rating{
    position: relative;
    width:100%;
    padding: 3rem 0 8rem;
}

.rating-body{
    position: relative;
    width:90%;
    margin:0 auto;
    max-width: 1600px;
}
.rating-cards{
    position: relative;
    margin-left: -30px;
    margin-right: -30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.rating-submit{
    padding: 0.5em 4em;
    border: none;
    border-radius: 0;
    background-color: #F6CD16;
    color: black;
    height: 52px;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px;
}

.rating-control{
    display: flex;
    justify-content: center;
    margin:3rem auto;
}

.rating-msg{
    font-family: 'Josefin Sans', sans-serif;
    width:90%;
    text-align: center;
    line-height: 1.5;
    margin:3rem auto;
}