@import "../../../variables.scss";

.btn-add-coins {
    padding: 0.5em 4em;
    border: none;
    border-radius: $border-radius;
    background-color: $primary-color;
    color: black;
    height: 52px;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px;
}

.modal-add-coins {
    .close-btn {
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        width: 40px;
        height: 40px;
        padding: 0;
        background-size: 40px;
        background-color: transparent;
        background-image: url("/img/close-icon.png");
        background-repeat:   no-repeat;
        background-position: top left;
    }

    .modal-header {
        background-color: $primary-color;
        font-family: 'Josefin Sans', sans-serif;
        font-size: 16px;
        color: #000000;
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        .credit-card-icon {
            height: 24px;
            margin-right: 12px;
        }
    }

    .modal-body {
        padding: 40px 80px;

        .btn-add-coins {
            padding: 0.5em 4em;
            border: none;
            border-radius: $border-radius;
            background-color: $primary-color;
            color: black;
            height: 52px;
            font-family: 'Josefin Sans', sans-serif;
            font-size: 16px;
            width: 100%;
            margin-top: 60px;
        }
    }

    .MuiFormGroup-root {
        display: block;       
        
        .MuiFormControlLabel-label {
            font-family: 'Josefin Sans', sans-serif;
            font-size: 16px;
            color: #000000;
        }

        .MuiRadio-colorSecondary.Mui-checked {
            color: $primary-color
        }
    }
    
    .description {
        font-family: 'Josefin Sans', sans-serif;
        font-size: 16px;
        line-height: 1.31;
        color: #000000;
        margin: 24px 0;
        
        .orange {
            color: $primary-color
        }
    }

    .label {
        font-family: 'Josefin Sans', sans-serif;
        font-size: 14px;
        color: #929292;
    }
}

.radio-groups{
    display:flex;
    justify-content: space-between;
    input{
        position:relative;
        bottom:-1px;
        margin-right:3px;
    }
}