@import "../../variables.scss";

#top-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

.transparent-backdrop {
  background-color: transparent;
}

#nav-container {
  height: 56px;
  font-size: 13px !important;
  background-color: #ffffff;
  border-width: 0;
  border-bottom: solid 1px $lighter-grey;
  margin-bottom: 0px;
  font-family: 'Josefin Sans', sans-serif;

  .mobile-only {
    display: none;

    li {
      list-style: none;
    }
  }

  .dropdown-menu {
    border-radius: $border-radius;
    font-size: 1.1em;
  }
  
  .dropdown-toggle {
    border-radius: $border-radius;
    background-color: transparent;
    border-color: $darker-grey;
  }


  .nosh-logo {
    height: 55px;
    margin-top: 0;
    margin-left: 5px;
    margin-right:50px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .nav-item a {
    font-family: 'Josefin Sans', sans-serif;
    color: $black-text-color;
    font-size: 14px;
  }

  .navbar-collapse {
    border-color: transparent;
    background-color: #ffffff;
    z-index: 99;
    padding-left: 10px;
    padding-right: 10px;
  }
  .navbar-collapse.collapse:not(.in) {
    .navbar-right {
      display: flex;
      align-items: center;
    }
    .myAccount-btn {
      border-radius: $border-radius;
      border: solid 1px $black-text-color;
      a {
        padding: 0.5em;
      }
    }
  }

  span.shopping-cart-container {
    text-decoration: none;
    display: flex;
  }

  .mobile-shopping-cart-container {
    background: transparent;
    appearance: none;
    padding:0;
    border:0;
    box-shadow: none;
    display: none;
  }

  .cart-items-badge {
    height: 20px;
    width: 20px;
    border-radius: 12px;
    background-color: #FC534F;
    color: white;
    font-size: 13px;
    font-family: 'Josefin Sans', sans-serif;
    line-height: 1.7;
    text-align: center;
  }

  .icon {
    height: 20px;
  }

}
.hover-shopping-cart-container {
  height: 200;
  width: 37;
}
.checkout-btn{
  width: 100%;
  background-color: $primary-color;
  color:#ffffff;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 18px;
}

.right-align {
  text-align: right;
}
.primary-color {
  color: $primary-color;
}
.vertical-spacing {
  margin-top: 1em;
  margin-bottom: 1em;
}
.close-btn:hover {
  background-color: transparent;
}

.close-btn:focus {
  background-color: transparent;
}

.close-btn-container {
  width: 100%;
  text-align: right;
  margin-bottom: -1em;
}

.close-btn {
  margin-top: -20px;
  margin-right: -20px;
  border: none;
  width: 50px;
  height: 50px;
  padding: 0;
  background-size: 40px;
  background-color: transparent;
  background-image: url("/img/close-icon.png");
  background-repeat:   no-repeat;
  background-position: top left;
}
@media (min-width: 768px) {
  #nav-container {
    .container {
      padding: 10;
    }
  }
}


@media (max-width: 768px) {
  .navbar-collapse.in {
    -webkit-box-shadow: -2px 7px 19px -8px rgba(0,0,0,0.75);
    -moz-box-shadow: -2px 7px 19px -8px rgba(0,0,0,0.75);
    box-shadow: -2px 7px 19px -8px rgba(0,0,0,0.75);
    position: relative;
  }

  .shopping-cart-container {
    display: none !important;
    height: 0px !important;
    width: 0px !important;
  }
  .mobile-shopping-cart-container {
    display: flex !important;
  }

  .desktop-only {
    display: inline;
  }

  .mobile-only {
    display: block !important;
  }

  #my-account-dropdown {
    display: none !important;
  }
}

@media (min-width: 768px) {
  #nav-container {
    .container {
      width: 100vw;
    }
  }

  .shopping-cart-preview-modal {
    position: fixed;
    // top: -60px;
    right: 30px;
  }
}

@media (min-width: 940px) {
  #nav-container {
    .container {
      width: 90vw;
    }
  }
}

@media (min-width: 1170px) and (max-width: 1385px) {
  #nav-container {
    .container {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .nav-item a {
      font-size: 0.95em;
      padding-left: 0.75em !important;
      padding-right: 0.75em !important;
    }
    .btn {
      font-size: 0.85em !important;
    }
  }
}
@media (min-width: 1070px) and (max-width: 1170px) {
  #nav-container {
    .container {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .nav-item a {
      font-size: 0.9em;
      padding-left: 0.5em !important;
      padding-right: 0.5em !important;
    }
    .btn {
      font-size: 0.8em !important;
    }
  }
}

@media (min-width: 940px) and (max-width: 1070px) {
  #nav-container {
    .container {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .nav-item a {
      font-size: 0.8em;
      padding-left: 0.3em !important;
      padding-right: 0.3em !important;
    }
    .btn {
      font-size: 0.7em !important;
    }
  }
}
@media (min-width: 769px) and (max-width: 940px) {
  #nav-container {
    .container {
      padding-left: 3% !important;
      padding-right: 3% !important;
    }
    .nav-item a {
      font-size: 0.7em;
      padding-left: 0.5em !important;
      padding-right: 0.5em !important;
    }
    .btn {
      font-size: 0.6em !important;
    }
  }
}

@media (max-width: 768px) {
  #nav-container {
    .container {
      padding-left: 5% !important;
      padding-right: 5% !important;
    }
    .nav-item a {
      font-size: 1.1em !important;
      margin-top: 1em;
    }
    .btn {
      font-size: 1.2em !important;
    }

    .nav-item {
      text-align: right;
    }

    .desktop-only {
      display: none;
    }
  }
}

#location-dropdown {
  border: 0;
}