@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:100,100i,300,300i,400,400i,600,600i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:100,100i,300,300i,400,400i,600,600i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Anton:100,100i,300,300i,400,400i,600,600i,700,700i');
@import "./variables.scss";

@mixin fontFace($family, $src, $style: normal, $weight: normal) {
  @font-face {
    font-family: $family;
    src: url("./fonts/#{$src}.ttf") format("truetype");
    font-style: $style;
    font-weight: $weight;
  }
}

@mixin a11y-hidden {
  position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
}

@include fontFace("Cocogoose", "Cocogoose-Pro-Light-trial", normal, 400);
@include fontFace("Cocogoose", "Cocogoose-Pro-Regular-trial", normal, 600);

#root {
  overflow-x: hidden;
  font-size: 14px;
}

.modal-dialog {
  font-size: 14px;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;

  &.no-scroll {
    overflow-y: hidden;
  }
}

button:focus {outline: transparent !important;}
input:focus {outline: transparent !important;}
.btn:focus {outline: transparent !important;}

.form-control {
  border-radius: $border-radius;
}

@media(max-width: 768px) {
  .modal-dialog {
    overflow: auto;
  }
}

.popup-img{
  width: 100%;
  height: auto;
}

.banner-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #f7d92a;
}

.closing-banner{
  object-fit: contain;
  max-width: 1366px;
  width: 94%;
  height: 94%;
}

















.form-control:focus {
  border-color: transparent;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.form-group {
  textarea {
    overflow: auto;
    border: none;
    background-color: transparent;
    box-shadow: none;
  }
  textarea:focus {outline: transparent !important}
}

.fluid-primary-btn {
  padding: 0.5em 4em;
  border: none;
  border-radius: $border-radius;
  background-color: $primary-color;
  color: black;
  height: 52px;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 16px;
  width: 100%;

  img {
    margin-left: 1em;
  }
}

.primary-color-btn {
  background-color: $primary-color;
  border: none;
  color: white;
}

.primary-color-btn:hover {
  background-color: $primary-color;
  border: none;
  color: white;
}

.gray-color {
  color: $light-grey !important;
}

.basic-btn {
  background-color: transparent;
  border: none;
}

.basic-btn:hover {
  background-color: transparent !important;
}

.basic-btn:active {
  background-color: transparent !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.basic-btn:focus {
  background-color: transparent !important;
}

.basic-btn:visited {
  background-color: transparent !important;
}

.flex-row-evenly {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.flex-row-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-row-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.center-align {
  text-align: center;
}

.btn {
  border-radius: $border-radius;
}

.white-rounded-bordered-btn {
  background-color: transparent;
  border-color: white;
  border-width: 3px;
  color: white;
  height: 50px;
  border-radius: 25px;
  font-size: 1.2em;
}

.white-rounded-bordered-btn:hover {
  background-color: transparent !important;
  color: white;
  border-color: white;
}
.white-rounded-bordered-btn:active {
  background-color: transparent !important;
  color: white;
  border-color: white;
}
.white-rounded-bordered-btn:focus {
  background-color: transparent !important;
  color: white;
  border-color: white;
}

.separator {
  height: 1px;
  background-color: #e7e7e7;
  margin: 1em 0;
}
