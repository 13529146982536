@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:100,100i,300,300i,400,400i,600,600i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:100,100i,300,300i,400,400i,600,600i,700,700i');

$primary-color: #F6CD16;
$light-primary-color: #000000;
$black-text-color: #272727;
$dark-grey: #4A4A4A;
$darker-grey: #555;
$hover-color: #63BBB0;
$light-grey:#a6a6a5;
$lighter-grey: #e7e7e7;
$red: #FC534F;
$border-radius: 0;
$main-font: "Josefin Sans", sans-serif;
$second-font: "Bebas Neue";
$matterhorn: #545454;
button:focus {outline: transparent !important;}
input:focus {outline: transparent !important;}
.btn:focus {outline: transparent !important;}
