@import "../../variables.scss";

.blog-container {
  font-family: 'Josefin Sans', sans-serif;
  width:100%
}
.blog-banner{
  margin-top: 30px;
  width:100%;
  text-align:center;
  padding-top:40px;
  padding-bottom:40px;
  background-color: $primary-color;
  color:#fff;
  font-size:45px;
}
.blog {
  text-align: center;
}
