@import "../../../variables.scss";

#profile-container {
  font-family: 'Josefin Sans', sans-serif;
  margin-top: 2em;
  margin-bottom: 2em;

  input {
    display: block;
    border-radius: 0;
    border: 0.5px solid $lighter-grey;
    width: 100%;
    margin-bottom: 15px;
    padding: 16px 16px;
    color: $matterhorn;
  }

  label {
    font-size: 1.4rem;
    color: $light-grey;
  }

  .error-message {
    width: 100%;
    color: red;
    margin-bottom: .5rem;
    font-size: 1rem;
    float: left;
  }

  .profile-title {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1.6em;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1em;
  }

  .panel {
    border-radius: $border-radius;
  }

  .title {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 1em;
    margin-top: 1em;
    text-align: center;
  }

  .desc{
    text-align: center;
    margin-bottom: 1em;
  }
  .big-desc{
    font-size: 2.4rem;
    // font-weight: bold;
    text-align: center;
    margin-bottom: 1em;
  }
  .referral-box{
    display: flex;
    justify-content: center;
    margin:0 auto 2em;
  }
  .referral-box-code,
  .referral-box-share{
    background-color: $primary-color;
    height:45px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .referral-box-code{
    font-size: 1.5rem;
    font-weight: bold;
    padding:0 15px;
    @media(max-width: 768px) {
      font-size: 1.1rem;
    }
  }
  .referral-box-share{
    font-size: 1.1rem;
    padding:3px 10px 0;
    &:before{
      content: '';
      display:block;
      width:14px;
      height:14px;
      margin-top:-2px;
      margin-right:5px;
      background: url(../../../img/share.svg) no-repeat center center;
      background-size: contain;
    }
  }

  .next-btn {
    padding: 8px 16px;
    border: none;
    border-radius: $border-radius;
    background-color: $primary-color;
    color: black;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.4rem;
    float: right;
  }

  #reset-password-form {
    position: relative;
  }

  .dimmed-loader {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffffff9e;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media(max-width: 768px) {
  #profile-container {
    width: 100%;
  }
}