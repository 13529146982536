@import "../../variables.scss";

.modal-popup-container {

  font-family: 'Josefin Sans', sans-serif;

  &.announcement-popup {
    @media(max-width: 768px) {
      padding-top: 60px !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
      &.modal.fade .modal-dialog {
        overflow: visible;
      }
    }

    @media(min-width: 768px) {
      .modal-dialog {
        height: calc(100vh - 60px);
        width: auto;
        text-align: center;
      }

      .modal-content,
      .modal-body,
      .modal-body>div {
        height: 100%;
        display: inline-block;
      }

      .popup-img {
        height: 100%;
        width: auto;
      }
    }

  }

  .modal-content {
    border: none;
    border-radius: 0;
  }

  .modal-body {
    padding: 36px;
  }

  .close-btn:hover {
    background-color: transparent;
  }

  .close-btn:focus {
    background-color: transparent;
  }

  .close-btn-container {
    width: 100%;
    text-align: right;
    margin-bottom: -1em;
  }

  .close-btn {
    //margin-top: -20px;
    //margin-right: -20px;
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    width: 50px;
    height: 50px;
    padding: 0;
    background-size: 40px;
    background-color: transparent;
    background-image: url("/img/close-icon.png");
    background-repeat: no-repeat;
    background-position: top left;
  }

  .next-btn {
    padding: 0.5em 4em;
    border: none;
    border-radius: $border-radius;
    background-color: $primary-color;
    color: black;
    height: 52px;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px;
    width: 100%;
    margin-bottom: 1rem;
  }
}

.backdrop {
  background: rgba(255, 255, 255, 0);
  width: 1000vw;
  height: 1000vh;
  z-index: -1;
  position: absolute;
  left: -1000px;
  top: -250px;
}