.menu-filter-modal {
    display: flex;
    flex-direction: row;
    align-self: right;
    align-items: right;
    justify-content: right;
    max-height: 100%;
    position: absolute;
    overflow: visible;
    height: 100%;
    width: 100%
}

.menuitem:focus {
    outline-width: 0;
}