@import "../../../variables.scss";

#payment-success-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Josefin Sans', sans-serif;

  .title {
    font-size: 26px;
  }
  .subtitle {
    font-size: 18px;
  }

  .separator {
    margin: 2em;
    height: 1px;
    background-color: $lighter-grey;
  }

  .btn {
    min-width: 240px;
    border-radius: $border-radius;
    background-color: $primary-color;
    border: none;
    color: black;
    margin-top: 14px;
    font-size: 14px;
    font-weight: 600;
    // height: 45px;
    padding: 12px 0;
  }

  .button-white { 
    background-color: white;
    border: 1px solid #dadada;
  }
}