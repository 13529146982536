@import "../../../variables.scss";

#mobile-app-promo {
  .modal-content {
    max-width: 420px !important;
    margin: 30px auto !important;
  }
  .modal-body {
    padding: 0 !important;
  }

  .mobile-btn-container { 
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    bottom: 8px;
    left: 0;
    right: 0;
  }

  #app-store-btn {
    img {
      width: 145px;
      height: auto;
    }
  }

  #google-play-btn {
    img {
      width: 160px;
      height: auto;
    }
  }
}

body {
  overflow-x: hidden;
}

body.modal-open { overflow:visible !important; }

#sidesModal {

  .modal-header {
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
  }

  .modal-dialog {
    font-size: 14px;
    max-width: 380px !important;
    margin: 200px auto !important;
    width: 3800px !important
  }

  .btn-default {
    background-color: #F6CD16 !important;
    color: black !important
  }

  .modal-backdrop {
    background-color: rgba(255, 255, 255, 0) !important;
  }

}

#order-container {

  overflow-x: hidden;

  .mobile-bottom-fixed-btn {
    display: none;
  }

  .dates-selector-grid {
    // position: fixed;
    // left: 0;
    // right: 0;
    // top: 6em;
    background-color: white;
    // z-index: 9;

    .row {
      margin-bottom: -0.75em;

    }
  }

  .added-or-skipped {
    color: white !important;
  }

  .arrow-btn {
    font-family: 'Roboto Condensed', sans-serif !important;
    font-weight: 600;
    font-size: 13px;
    border: none;
    height: 50px;
    width: 100px;
    margin-right: -14px;
    background-color: transparent;
    padding-right: 1.25em;
    padding-bottom: 0;
    padding-top: 0.1em;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top:0;
      left: 0;
      opacity:0;
    }
    &:hover,
    &.active{
      img {
        opacity:1;
      }
    }
  }
  .category-container{
    margin-bottom:2rem;
  }
  .category-container .row div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: black;
    font-weight: 600;
    font-family: 'Josefin Sans', sans-serif;
  }

  .btn-category {
    width:100%;
    background: $primary-color;
    border: none;
    text-transform: capitalize;
    padding:0 .75rem;
    margin:1rem auto;
    max-width:120px;
    height:55px;
    white-space: normal;
    &:disabled {
      background:#aaa;
      display:none;
    }
  }

  .btn-category:focus { 
    // background-color: transparent;
  }

  .next {
    height: 50px;
    margin-left: 20px;
    background-color: transparent;
    border: none;
    box-shadow: none !important;
  }

  .prev { 
    height: 50px;
    margin-right: 20px;
    background-color: transparent;
    border: none;
    box-shadow: none !important;
  }

  .arrow-btn.active {
    box-shadow: none;

    .day-date {
      border-bottom: solid 4px $primary-color;
    }
  }

  .arrow-btn:hover {
    border: none;
    box-shadow: none;
  }

  .disabled-btn {
    color: $light-grey !important;
  }
  .dropdown-menu {
    border-radius: $border-radius;
  }
  .dropdown-toggle {
    border-radius: $border-radius;
  }

  .dates-selector-container {
    text-align: center;
    margin-bottom: 0.75em;
    .btn {
      font-family: 'Josefin Sans', sans-serif;
      font-size: 16px;
      border-radius: $border-radius;

      $arrowWidth:3px;
      &.prev{
        position:relative;
        $size:30px;
        width:$size;
        height:$size;
        border-radius: 100%;
        background:transparent;
        margin:1rem auto;
        display:flex;
        align-items: center;
        justify-content: center;
        font-size:0;
        padding:0;
        @media(max-width: 540px) {
          margin-right:-5px;
        }
        @media(max-width: 480px) {
          margin-right:0;
        }
        &:before{
            position:relative;
            content:'';
            display:block;
            $size:10px;
            width:$size;
            height:$size;
            flex:0 0 auto;
            border-top:$arrowWidth solid $primary-color;
            border-left:$arrowWidth solid $primary-color;
            transform: rotate(-45deg);
            transform-origin: center center;
            left:-7.5%;
        }
      }
      &.next{
        position:relative;
        $size:30px;
        width:$size;
        height:$size;
        border-radius: 100%;
        background:transparent;
        margin:1rem auto;
        display:flex;
        align-items: center;
        justify-content: center;
        font-size:0;
        padding:0;
        margin-left:15px;
        @media(max-width: 540px) {
          margin-left:10px;
        }
        @media(max-width: 480px) {
          margin-right:5px;
        }
        &:before{
            position:relative;
            content:'';
            display:block;
            $size:10px;
            width:$size;
            height:$size;
            border-top:$arrowWidth solid $primary-color;
            border-left:$arrowWidth solid $primary-color;
            transform: rotate(135deg);
            transform-origin: center center;
            left:-7.5%;
        }
      }
    }
    .btn:not(.active) {
      color: $black-text-color;
    }
    .btn.active {
      color: $light-primary-color
    }
  }

  .filter-container {
    margin-right: 2em;
    .dropdown {
      border-radius: $border-radius;
      font-family: 'Josefin Sans', sans-serif;
      font-size: 14px;
    }
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .promotion-text {
    width: 100%;
  }

  .center-title {
    font-family: 'Josefin Sans', sans-serif;
    text-align: center;
    font-size: 1.2em;
    margin-top: 0.25em;
  }

  .week-title {
    font-family: 'Roboto Condensed', sans-serif;
    color: $black-text-color;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0.25em;
    margin-top: 0.25em;
  }

  .separator {
    height: 1px;
    background-color: $lighter-grey;
    margin: 24px 0;
  }

  .meals-container {
    margin-top: 2em;
    margin-bottom: 2em;
    display: flex;
    flex-wrap: wrap;
  }

  .section-title {
    font-size: 20px;
    font-family: 'Josefin Sans', sans-serif;
    color: black;
    font-weight: bold;
    text-decoration: underline;
    text-decoration-color: rgb(246,205,22);
    text-transform: capitalize;
  }

  .section-subtitle {
    font-size: 14px;
    font-weight: 300;
    font-family: 'Josefin Sans', sans-serif;
    color: $light-grey;
  }

  .flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 2em;
    margin-top: 2em;
    flex-wrap: wrap;
  }

  .next-week-menu-info-text {
    font-family: "Roboto Condensed", sans-serif;
    background-color: #e45c49;
    border-radius: $border-radius;
    color: white;
    padding: 0.5em;
    border: none;
    margin-top: 0.5em;
    font-size: 1em;
    max-width: 426px;
    width: 90vw;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      background-color: transparent;
      border: none;
      border-left: solid 1px white;
      font-family: "Josefin Sans", sans-serif;
    }

    img {
      width: 22px;
    }
  }

  .next-week-menu {
    font-family: "Roboto Condensed", sans-serif !important;
    background-color: #000000;
    border-radius: $border-radius;
    color: white !important;
    padding: 0.5em !important;
    border: none !important;
    margin-top: 0.5em !important;
    font-size: 1em !important;
    max-width: 426px !important;
    width: 90vw !important;
    margin-bottom: 0;
    display: flex !important;
    justify-content: space-between;
    align-items: center !important;
    justify-content: center !important;

    img {
      width: 22px;
    }
  }

  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flex-between {
    display: flex;
    justify-content: space-between;
  }

  .total-text {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 2.2em;
    margin-right: 1em;
    color: $black-text-color;
    span {
      font-weight: bold;
      color: $primary-color;
    }
  }

  .selected-date-container {
    margin-top: 2em;
  }

  .primary-color {
    color: $light-primary-color;
  }

  .selected-date-text {
    font-family: 'Josefin Sans', sans-serif;
    color: $black-text-color;
    font-size: 20px;
  }

  .skip-day-btn {
    border-radius: $border-radius;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 12px;
    margin-left: 1em;
    background-color: $lighter-grey;
    border-color: darkgrey;
    color: gray;
  }

  .next-btn {
    padding: 0.5em 4em;
    border: none;
    border-radius: $border-radius;
    background-color: $primary-color;
    color: black;
    height: 52px;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px;
    
    img {
      margin-left: 1em;
    }
  }

  .border-btn {
    padding: 0.5em 4em;
    border: 1px solid black;
    border-radius: $border-radius;
    background-color: white;
    color: black;
    height: 52px;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px;
    margin-right: 12px;

    img {
      margin-left: 1em;
    }
  }

}

@media(max-width: 540px) {
  .meals-container {
    margin-left: 0;
    margin-right: 0;
  }
  .selected-date-container {
    margin-left: 1em;
  }
  .filter-container {
    margin-left: 1em;
    margin-right: 2em;
  }

  #order-container {
    .arrow-btn {
      width: 80px;
      margin-right: -11px;
      font-size: 13px !important;
    }
    .total-text {
      width: 100px !important;
      margin-left: 10px !important
    }
   
  }

}
@media(max-width: 480px) {
  #order-container {
    .arrow-btn {
      width: 55px;
      margin-right: -7px;
      font-size: 12px !important;
    }
  }
}

@media(max-width: 768px) {

  .desktop-only {
    display: none !important;
  }

  .justify-content-flex-end {
    justify-content: flex-end;
  }

  .justify-content-space-around {
    justify-content: space-around;
  }

  .mobile-bottom-fixed-btn {
    // cursor: pointer;
    display: flex !important;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    bottom: 0;
    z-index: 99;
    left: 0;
    right: 0;
    width: 100%;
    background-color: $primary-color !important;
    margin-bottom: 0 !important;
    padding:1rem 0;
    padding-left:5%;
    padding-right:90px;

    .total-text {
      font-size: 16px !important;
      color: white !important;
      margin-right: 0 !important;
      #order-container & {
        margin-left: 0 !important;
        width: 100% !important;
        padding:0;
        margin-bottom:.5rem;
      }
    }

    .border-btn{
      #order-container & {
        width:auto;
        background:transparent;
        border:0;
        box-shadow: none;
        font-size: 13px;
        height: auto;
        color: #000;
        padding:.5rem 0;
        margin-right:3rem;
        img {
          margin-left: 0.5em !important;
          width: 14px;
        }
      }
    }

    .next-btn {
      padding-left: 0 !important;
      padding-right: 0.2em !important;
      #order-container & {
        height: auto;
        padding:.5rem 0;
      }
      span {
        font-size: 13px;
        color: #000;
      }
      img {
        margin-left: 0.5em !important;
        width: 14px;
      }
    }
  }
}

@media(max-width: 540px) {
  #sidesModal {

    .modal-dialog {
      max-width: 325px !important;
      margin: 20px auto !important;
    }


    
  }

  #mobile-app-promo {
    .modal-content {
      max-width: 325px !important;
      margin: 20px auto !important;
    }

    #app-store-btn {
      bottom: 140px !important;
      img {
        width: 110px !important;
        height: auto;
      }
    }

    #google-play-btn {
      bottom: 90px !important;
      img {
        width: 120px !important;
        height: auto;
      }
    }
  }

  .flex {
    flex-wrap: wrap;
  }
  .promotion-text {
    margin-top: 1em;
    width: 100% !important;
  }
}

@media print{
  #order-container{
    .col-md-4.col-sm-6.col-xs-12 {
      width:50%;
    }
  }
}

.catering-order{
  margin-top:3rem;
  margin-bottom:3rem;
}
.catering-order-table{
  width:100%;
  display:table;
  margin-top:3rem;
}
.catering-order-table-heads,
  .catering-order-table-item{
  display:table-row;
}
.catering-order-table-head,
  .catering-order-table-info{
    display:table-cell;
    font-family: 'Josefin Sans', sans-serif;
    color: $black-text-color;
    font-size: 16px;
    @media(max-width: 540px) {
      font-size: 12px;
    }
    padding: 0 1rem 1em;
    &:first-child{
      padding-left:0;
    }
    &:last-child{
      padding-right:0;
    }
  }

  .catering-order-table-perprice,
  .catering-order-table-quantity,
  .catering-order-table-price{
    text-align: center;
  }
  .catering-order-table-item:last-child{
    .catering-order-table-perprice,
    .catering-order-table-quantity,
    .catering-order-table-price{
      border-bottom:1px solid rgba($black-text-color,0.5);
    }
  }

  .catering-order-totalprice{
    width:100%;
    display:flex;
    justify-content: flex-end;
    font-family: 'Josefin Sans', sans-serif;
    color: $black-text-color;
    font-size: 20px;
    @media(max-width: 540px) {
      font-size: 16px;
    }
    margin:2rem 0;
  }
  .catering-order-table-price,
    .catering-order-totalprice-price{
    width:100px;
  }
  .catering-order-totalprice-label{
    margin-right:1rem;
  }
  .catering-order-totalprice-price{
  text-align: center;
}

$gutter:30px;
.catering-order-fields{
  display:flex;
  flex-wrap: wrap;
  margin-left:-$gutter/2;
  margin-right:-$gutter/2;
  margin-bottom:2rem;
}
.catering-order-field-holder{
  box-sizing: border-box;
  width:50%;
  padding:$gutter/2;
  @media(max-width: 540px) {
    width:100%;
  }
}
.catering-order-field-label{
  margin-bottom:.5rem;
  font-family: 'Josefin Sans', sans-serif;
  color: $black-text-color;
  font-size: 16px;
  @media(max-width: 540px) {
    font-size: 12px;
  }
}
.catering-order-field{
  width:100%;
}
.catering-order-input{
  width:100%;
  height:35px;
  padding:0 1rem;
}

#desktop-action-buttons { 
  margin-bottom: 12px;
}
.category-filter-slider{
  position:relative;  
  padding:0 45px;
  margin-bottom:3rem;
  text-align: center;
  .swiper-wrapper{
    // justify-content: center;
  }
}
.category-filter-slider-body{
  position:relative;  
  width:100%;
}
.category-filter-slider-control{

.swiper-custom-arrow-right{
  position:relative;
  $size:30px;
  width:$size;
  height:$size;
  border-radius: 100%;
  background:#FFF;
  margin:1.5rem auto;
  display:flex;
  align-items: center;
  justify-content: center;
  &:before{
      position:relative;
      content:'';
      display:block;
      $size:35%;
      width:$size;
      height:$size;
      border-top:2px solid $primary-color;
      border-left:2px solid $primary-color;
      transform: rotate(135deg);
      transform-origin: center center;
      left:-7.5%;
  }
}
.swiper-custom-arrow-left{
  position:relative;
  $size:30px;
  width:$size;
  height:$size;
  border-radius: 100%;
  background:#FFF;
  margin:1.5rem auto;
  display:flex;
  align-items: center;
  justify-content: center;
  &:before{
      position:relative;
      content:'';
      display:block;
      $size:35%;
      width:$size;
      height:$size;
      border-top:2px solid $primary-color;
      border-left:2px solid $primary-color;
      transform: rotate(-45deg);
      transform-origin: center center;
      left:-7.5%;
  }
}
.prev-el {
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
  cursor: pointer;
}
.next-el {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  cursor: pointer;
}
.prev-el,
  .next-el {
    &.swiper-button-disabled {
      opacity:0.2;
      cursor: not-allowed;
    }
  }
}

.a11y-hidden {
  position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
}