@import "../../variables.scss";
.tnc-content{
  width: 90%;
  max-width: 1200px;
  text-align: left;
  margin:0 auto;
  padding:4rem 0;
  h2{
    margin-bottom: 2rem;
  }
}