@import "../../../../variables.scss";


.rating-card{
    position: relative;
    padding:30px;
    width:33.3333%;
    @media(max-width: 1023px) {
        width:50%;
    }
    @media(max-width: 767px) {
        width:100%;
    }
}
.rating-card-body{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.rating-card-info{
    width: 100%;
    margin:0 auto;
    text-align: center;
    flex:0 0 auto;
}
.rating-card-img{
    position: relative;
    display:block;
    width:100%;
    background: no-repeat center center;
    background-size: cover;
    margin-bottom:30px;
    &:before{
        content: '';
        display:block;
        padding-bottom:66.6666%;

    }
}
.rating-card-name{
    font-family: 'Josefin Sans', sans-serif;
    font-size:18px;
    margin-bottom:15px;
}
.rating-card-rating{
    margin-bottom:15px;
}
.rating-star{
    $size:36px;
    width: $size;
    height: $size;
    border-radius: $size/6;
    font-size: 0;
    background: url(../../../../img/rating/img-star.svg) no-repeat center 35% #eee;
    background-size: 70%;
    margin:0 0.3rem;
    border:0;
    &.mod-hover{
        background-color:rgba(#FBD721, 0.5);
    }
    &.mod-active{
        background-color:#FBD721;
    }
}
.rating-comment-box{
    width:100%;
    text-align: left;
}
.rating-comment-label{
    font-family: 'Josefin Sans', sans-serif;
    font-size:18px;
    margin-bottom:5px;
}
.rating-comment-input{
    border: 1px solid #000;
    resize: none;
    width:100%;
    height:100px;
    padding:10px;
    font-family: 'Josefin Sans', sans-serif;
    font-size:16px;
}