
.partners-logos{
  position:relative;
  width:100%;
  text-align: center;
  overflow:hidden;
  height:auto;
  display:block;
  max-height: initial;
  max-height: none;
  // @include mobileView() {
  //   display:block;
  // }
}
.partners-logos-inner{
  position:relative;
  width:100%;
  box-sizing: border-box;
  padding:2rem 3rem;
  // @include mobileView() {
  //   padding:3rem 3rem;
  // }
}
.partners-logos-title{
  margin: 0 auto 2rem;
  // font-family: $fontSecondary;
  // color:$main;
  // font-size: $bigFont;
  // @include mobileView() {
  //   font-size:$mediumFont;
  //   font-weight:bold;
  // }
  .checkout-success &{
    display:none;
  }
}
.partners-logos-checkout-title{
  margin: 0 auto 2rem;
  // font-family: $fontSecondary;
  // color:$main;
  display:none;
  // font-size: $mediumFont;
  // @include mobileView() {
  //   font-size:$mediumFont;
  //   font-weight:bold;
  // }
  .checkout-success &{
    display:block;
  }
}
.partners-logos-items{
  display:flex;
  align-items: center;
  justify-content: center;
  width:90%;
  max-width: 1200px;
  margin:0 auto;
}
.partners-logos-item{
  position: relative;
  width:50%;
  max-width:250px;
  padding:0 3rem;
  .checkout-success &{
    max-width:160px;
    padding:0 2rem;
  }
  &.mod-south{
    .partners-logos-item-img{
      width:80%;
    }
  }
}
.partners-logos-item-img{
  position: relative;
  display:block;
  width:100%;
  background: no-repeat center center;
  background-size: contain;
  &::before{
    content: '';
    display:block;
    padding-bottom: 100%;
  }
}
