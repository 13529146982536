@import "../../../variables.scss";

#order-history-container, #order-history-details-container {

  font-family: 'Josefin Sans', sans-serif;
  margin-bottom: 2em;

  .title {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 500;
    font-size: 1.6em;
    margin-top: 1em;
    margin-bottom: 1em;
    text-align: center;
  }

  .items-date-title {
    color: $primary-color;
    font-size: 1.2em;
  }

  .margin-top {
    margin-top: 1em;
  }

  .summary-info-container {
    font-size: 1.1em;
  }

  .order-item-container {
    padding: 1em;
    border: solid 1px $lighter-grey;
    margin-top: 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  .nutrition-intake-container {
    margin-top: 1em;
    color: $light-grey;
  }

  .nutrition-intake-content {
    color: $light-grey;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: bold;
  }

  .lighter-grey {
    color: $lighter-grey
  }

  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flex {
    display: flex;

    div {
      margin-right: 1em;
    }
  }

  .no-orders-container {
    color: $light-grey;
    font-size: 1.4em;
    text-align: center;

    .underlined {
      width: 100%;
      height: 1px;
      background-color: $light-grey;
      margin-top: 1em;
    }
  }

  .btn {
    border-radius: $border-radius;
    background-color: $primary-color;
    border: none;
    color: white;
  }

  .cart-item-details-container {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 1.1em;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .quantity-container {
      margin-right: 1em;
      font-size: 1.1em;
    }

    .meal-title {
      font-size: 1.1em;
      margin-bottom: -0.5em;
    }
    .nutrition-intake-container {
      font-size: 0.8em !important;
    }

    .rate-dish-container {
      margin-top: 1em;
      display: flex;
      flex-direction: row;
      align-items: center;

      .btn {
        margin-left: 1em;
      }
    }

  }

  .separator {
    background-color: $lighter-grey;
    height: 1px;
    margin-top: 2em;
    margin-bottom: 2em;
    width: 100%;
  }

  .order-total-amount {
    font-size: 1.8em;
    font-weight: 600;
  }
}

@media(max-width: 768px) {
  .container {
    width: 100%;
  }
}
