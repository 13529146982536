@import "../../variables.scss";

.input-field-container {

  font-family: 'Josefin Sans', sans-serif;

  label {
    font-weight: 400;
  }
  .form-control {
    border-radius: $border-radius;
  }
}