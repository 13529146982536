@import "../../variables.scss";

.cutOffTime-container {
  padding: 0.25em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $light-primary-color;

  .cutOffTime-text {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: white;
    text-align: center;
  }
  .time {
    margin-left: 1em;
  }
}