@import "../../variables.scss";

.home-banner-container {
  width: 100%;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  background-position: center center;
  background-size:cover;
  height:300px;
  background: #FBD721;
  @media(max-width: 767px) {
    display: block;
    height:auto;
  }


  .home-banner-bg{
    width: 50%;
    height:100%;
    position: relative;
    background-position: center center;
    background-size:cover;
    @media(max-width: 767px) {
      width: 100%;
      background: #FBD721;
    }
    img {
      width: 100%;
      display: none;
      @media(max-width: 767px) {
        display: block;
      }
    }
  }

  .home-banner-content {
    width: 50%;
    height:100%;
    position: relative;
    right: 0;
    color: #272727;
    font-weight: bold;
    font-family: 'Cocogoose', 'Josefin Sans', sans-serif;
    font-size: 3rem;
    white-space: pre-wrap;
    padding:0 5%;
    display:flex;
    align-items: center;
    @media(max-width: 1200px) {
      font-size: 2.5rem;
    }
    @media(max-width: 1024px) {
      font-size: 2rem;
    }
    @media(max-width: 767px) {
      position: relative;
      width:100%;
      padding:3rem 5%;
      background: #FBD721;
    }
  }
  .home-banner-txt {
    display:block;
    strong{
      font-weight: bold;
      display:block;
      font-size: 1.2em;
      margin-bottom:2rem;
    }
    span{
      font-weight: normal;
      display:block;
    }
  }
}