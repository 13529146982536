@import "../../variables.scss";

.question {

  text-align: center !important;
  font-size: 16px;
  background-color: #fff !important;
  color:$black-text-color !important;
  border-style: solid !important;
  border-color: $lighter-grey !important;
  border-width: 1px !important;
  padding-bottom: 10px !important;
  padding-top:10px !important;
}
.question:hover {
    background-color: $primary-color !important;
    color:#fff !important;
    
}
.answer{
    padding-bottom: 20px !important;
    padding-top:20px !important;
    border-style: solid !important;
    border-color: $lighter-grey !important;
    border-width: 1px !important;
    font-size: 16px
}
.single-faq-container{
    margin-top: 20px !important;
}
