@import "../../variables.scss";

.shopping-cart-container {
  font-family: 'Josefin Sans', sans-serif;
  margin-right: 16px;
}

.shopping-cart-preview {

  .col {
    padding: 0;
  }

}

.dimmed-loader {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff9e;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}

.strikethough-text{
  text-align: left;
  text-decoration: line-through;
  margin-right: 0.25em;
  color:$light-grey;
}

@media(max-width: 768px) {
  .shopping-cart-preview-modal {
    display: none !important;
  }

}
