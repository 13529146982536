@import "../../variables.scss";

.modal-location-not-found {
  .modal-body {
    padding: 58px 24px;
  }

  .modal-content  {
    .title {
      font-family: 'Josefin Sans', sans-serif;
      font-size: 2.1rem;
      font-weight: bold;
      text-align: center;
      color: #000000;
      margin-bottom: 26px;
    }
    
    .description {
      font-family: 'Josefin Sans', sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
      text-align: center;
      color: #000000;
      margin-bottom: 26px;
    }
    
    .btn-container {
      display:flex;
      flex-direction: row; 

      @media(max-width: 640px) {
        flex-direction: column;
      }
    }

    .btn {
      flex: 1;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 600;
      font-family: 'Josefin Sans', sans-serif;
      text-align: center;
      color: #000000;
      padding: 16px 0;
    }

    .border-btn {
      border: solid 1px $black-text-color;
      background-color: #ffffff;
    }

    .solid-btn {
      margin-left: 8px;
      background-color: #f6cd16;
      border: solid 1px #f6cd16;

      @media(max-width: 640px) {
        margin-left: 0;
        margin-top: 8px;
      }
    }
  }
}

#company-signup-container {
  font-family: 'Josefin Sans', sans-serif;
  width: 100%;

  .spoonful-app-feature {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 2.6rem;
    font-weight: 600;
    text-align: center;
    color: #eab500;
    margin: 120px 8px 24px 8px;
    @media(max-width: 640px) {
      margin-top: 60px;
    }
  }

  .banner-container { 
    display: flex;
    flex: row;
    align-items: center;
    height: 600px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 0 0;
    }

    .location-container {
      position: absolute;
      right: 48px;
      background-color: rgba(255,255,255,0.85);
      padding: 24px;
      max-width: 740px;
      
      form {
        background-color: white;

        .home-hero-input{
          display:flex;
          justify-content: space-between;
        }

        .input-group {
          display:block;
          width:100%;
          flex: 1;
        }

        .input-group-addon {
          background-color: white;
          border-right-width: 0;
          display:none;
        }

        input:focus {
          border-color: #ccc;
        }

        img {
          height: 36px;
          width: 36px;
        }

        input {
          // border-left-width: 0;
        }
      }

      .location-input {
        height: 70px;
        background-color: white;
        @media(max-width: 768px) {
          height: 40px;
        }
      }

      button {
        font-family: 'Josefin Sans', sans-serif;
        height: 70px;
        background-color: #eab500;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        color: #ffffff;
        white-space: break-spaces;
        @media(max-width: 768px) {
          height: 40px;
          font-size: 10px;
        }
      }
    }

    .title { 
      font-family: 'Josefin Sans', sans-serif;
      font-size: 5.4rem;
      font-weight: bold;
      font-stretch: condensed;
      font-style: normal;
      line-height: 1.09;
      letter-spacing: normal;
      color: #2a2a2a;
      white-space: break-spaces;
      margin:0 0 3rem;
    }

    .text {
      font-family: 'Josefin Sans', sans-serif;
      font-size: 1.8rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: $black-text-color;
      white-space: break-spaces;
      margin: 30px 0;
      @media(max-width: 768px) {
        font-size: 1.5rem;
        line-height: 1.5em;
      }
    }
  }
  
  .price-plan-container {
    font-family: 'Josefin Sans', sans-serif;
    padding: 28px 56px 28px 54%;
    color: $black-text-color;
    background-color: #f4f4f4;
    font-size: 1.8rem;
    text-align: start;

    @media(max-width: 768px) {
      padding: 28px 10%;
      font-size: 1.5rem;
    }

    img { 
      width: 50%;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      object-fit: cover;
      
      @media(max-width: 768px) {
        display: none;
      }
    }

    .bold {
      font-size: 3.2rem;
      font-weight: bold;
      color: black;
      @media(max-width: 768px) {
        font-size: 2.4rem;
      }
    }

    .orange {
      color: #eab500;
    }

    .margin-bottom {
      margin-bottom: 22px;
    }
  }

  .app-feature-description {
    white-space: pre-wrap;
  }

  .app-feature-icon {
    height: 80px;
  }

  .app-feature-btn-row{
    margin-top:60px;
  }

  .app-feature-btn{
    font-family: 'Josefin Sans', sans-serif;
    background-color: #eab500;
    color:#272727;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    padding:15px 45px;
    border-radius: 5px;
    &:hover{
      text-decoration: none;
    }
    @media(max-width: 768px) {
      font-size: 16px;
      padding:12px 30px;
    }
  }

  .horizontal-frame {
    height: 4px;
    flex: 1;
    background-color: black;
  }
  
  .vertical-frame-left { 
    border-left: 4px solid black;
  }

  .vertical-frame-right { 
    border-right: 4px solid black;
    flex: 1,
  }

  .vertical-frame-left,
  .vertical-frame-right {
    padding: 24px 0;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .description {
      font-family: 'Josefin Sans', sans-serif;
      font-size: 2.6rem;
    }

    .app-feature-icon {
      width: 100px;
      align-self: center;
      height: unset;
    }
  }

  .frame-top {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    .spoonful-app-feature {
      top: 1.4rem;
      position: relative;
    }
  }

  .vertical-separator {
    height: 200px;
    border-right: 0.5px solid #979797;
  }

  .frame-bottom {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 2.2rem;
    font-weight: 600;
    color: #000000;
    
    .spoonful-app-feature {
      top: -1.3rem;
      position: relative;
      font-size: 1.8rem;
      color: #000000;

      .orange {
        color: #efcb00;
      }
    }
  }

  .banner-download-app {
    width: 100%;
    min-height: 480px;
    object-fit: cover;
  }


  .download-app {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 2.8rem;
    margin-top: 90px;
    font-weight: bold;
    letter-spacing: normal;
    color: #2a2a2a;
    text-align: center;

    @media(max-width: 1200px) {
      margin-top: 45px;
    }
    @media(max-width: 768px) {
      font-size: 2.6rem;
      margin-top: -2rem;
    }
  }

  .download-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 24px;

    @media(max-width: 768px) {
      justify-content: center;
      margin-bottom: 24px;
    }

    a {
      margin: 0 4px;
      width: 40%;
      max-width: 150px;
      &:first-child:last-child{
        width: 40%;
      }
      
      @media(max-width: 768px) {
        width: 25%;
        &:first-child:last-child{
          width: 50%;
        }
      }
    }

    img {
      // height: 70px; 
      width: 100%;
      object-fit: contain;
    }
  }

  .app-screen {
    width: 100%;
    object-fit: contain;
    max-width:300px;
  }

  .plate-left {
    position: absolute;
    left: 0;
    height: 370px;
  }

  .plate-right {
    position: absolute;
    right: 0;
    height: 370px;
  }

  .hkd {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 5.4rem;
    font-weight: bold;
    color: #efcb00;

    .black {
      color: black;
    }
  }


  #customers-carousel {
    width: 90vw;
    margin: 0 auto;
    position: relative;
    text-align: center;
    .rcs_dotsSet {
      visibility: hidden;
    }

    .rcs_arrowBtn {
      background-color: $primary-color !important;
      width: 40px !important;
      height: 40px !important;
      margin: 0 !important;
    }

    .rcs_right {
      top: 40%;
    }

    .rcs_left {
      top: 40%;
    }

    .rcs_sliderBox {
      height: 200px !important;
      background-color: transparent !important;
    }

    .itemWrapper {
      margin: 0 10px !important;
      height: auto;
      background-color: transparent !important;
    }
  }

  #testimonial-carousel {
    width: 80vw;
    margin: 0 auto;
    position: relative;

    .rcs_dotsSet {
      visibility: visible;
      //color: $primary-color;
      //background-color: #F6CD16;
    }

    .rcs_arrowBtn {
      background-color: $primary-color !important;
      margin: 0 !important;
    }

    .rcs_right {
      top: 40%;
    }

    .rcs_left {
      top: 40%;
    }

    .rcs_sliderBox {
      height: auto !important;
      background-color: transparent !important;
    }

    .itemWrapper {
      height: 60%;
      background-color: transparent !important;
      //padding: 30px;
    }
  }

  .plan-common {
    background-color: #ffffff;
    margin: 1em;
    padding: 1em 3em;
    text-align: center;

    table th {
      text-align: center;
      padding: 1em;
      color: $primary-color;
      text-decoration: underline;
      text-decoration-color: $primary-color;

    }

    table, th, tr {
      height: 3.5em;
    }

    height: 100%;
    font-size: 16px;
    max-width: 350px;
  }

  .green-color {
    color: $primary-color;
  }

  .bottom-btn-table {
    color: white;
    background-color: $primary-color;
  }

  .content {
    //padding: 50px;
    font-size: 16px;
  }

  .writer {
    padding: 10px;
  }

  .testimonial-card-container {
    padding-top:40px;
    width:700px;
  }

  .vertical-spacing {
    margin-top: 2em;
    margin-bottom: 1em;
  }

  .flex-center-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 1em 0 1em 0;
  }

  .next-btn {
    padding: 0.5em 4em;
    border: none;
    border-radius: $border-radius;
    background-color: $primary-color;
    color: black;
    height: 52px;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px;
  }

  .flex-center {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
  }

  .flex-column {
    flex-direction: column;
    -webkit-flex-direction: column;
  }

  .signup-steps-container {
    padding-top: 2em;
    padding-bottom: 2em;
    text-align: center;

    .title {
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 1.4em;
    }

    .step-title {
      font-family: 'Josefin Sans', sans-serif;
      font-size: 1.4em;
      text-align: center;
      margin-left: 0.5em;
      margin-right: 0.5em;
      margin-top: 1em;
      margin-bottom: 1em;
      cursor: pointer;

      &.active {
        color: $primary-color;
        border-bottom: solid 3px $primary-color;
      }
    }

    .row {
      display: flex;
      -ms-box-orient: horizontal;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -moz-flex;
      display: -webkit-flex;
      display: flex;
      flex-flow: row wrap;
      -webkit-flex-flow: row wrap;

      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center;
      padding-left: 12vw;
      padding-right: 12vw;
      flex: 1;
      flex-wrap: wrap;

      .col {
        width: 50%;
      }
    }

    .step-screenshot {
      width: 30vw;
      max-width: 220px;
      height: auto;
    }
  }

  #success-message {
    text-align: center;

    .title {
      font-size: 1.6em;
    }

    .subtitle {
      font-size: 1.2em;
      color: $light-grey;
    }
  }

  .features-image {
    //margin-top: 10em;
    //padding-bottom: 2em;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100vw;
    min-height: 30vh;
  }

  .company-signup-header-background {
    background-image: linear-gradient(
                       rgba(0, 0, 0, 0.5),
                       rgba(0, 0, 0, 0.5)
                      ),url("/img/company-signup/header2.jpg");
    transition: background-image 5s linear;
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
         -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;
            @keyframes fadein {
              from { opacity: 0; }
              to   { opacity: 1; }
          }
          
          /* Firefox < 16 */
          @-moz-keyframes fadein {
              from { opacity: 0; }
              to   { opacity: 1; }
          }
          
          /* Safari, Chrome and Opera > 12.1 */
          @-webkit-keyframes fadein {
              from { opacity: 0; }
              to   { opacity: 1; }
          }
          
          /* Internet Explorer */
          @-ms-keyframes fadein {
              from { opacity: 0; }
              to   { opacity: 1; }
          }
          
          /* Opera < 12.1 */
          @-o-keyframes fadein {
              from { opacity: 0; }
              to   { opacity: 1; }
          }
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top left;
    width: 100vw;
    min-height: 75vh;
    display: flex;
    align-items: center;


    h1 {
      font-family: 'Josefin Sans';
      color: white;
      font-size: 2.2em;
      font-weight: 600;
    }

    h4 {
      font-family: 'Josefin Sans';
      color: white;
      line-height: 1.7em;
    }

    .signup-now-btn {
      width: 200px;
      margin: 20px;
    }

    .yellow-btn { 
      color: #f9a705;  
      border-color: #f9a705;  
    }
  }

  .horizontal-separator-yellow {
    //align-self: 'center';
    background-color: #f9a705;
    height: 3px;
    width: 30em;
  }

  .eat-smarter-container {
    //padding-top: 2em;
    padding-bottom: 2em;

    h3 {
      padding: 1em;
    }

    .description {
      font-size: 1.1em;
      color: $black-text-color;
    }

    .horizontal-separator {
      height: 3px;
      background-color: $primary-color;
      flex-basis: 10%;
    }
    .horizontal-separator-2 {
      height: 3px;
      background-color: black;
      flex-basis: 10%;
    }

  }
  .company-signup-background {
    background-image: url("/img/company_sign_up_tuna.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top left;
    width: 100vw;
    min-height: 90vh;
    display: flex;
    align-items: center;
  }

  .plans-background {
    background-image: linear-gradient(
                    rgba(0, 0, 0, 0.5),
                    rgba(0, 0, 0, 0.5)
    ),
    url("/img/company-signup/CompanySignupFormBG2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top left;
    width: 100vw;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .row {
      margin-left: 0px;
      margin-right: 0px;
    }

    .item{ 
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 8px;
      text-align: center;
    }

    .icon {
      height: 48px;
      width: 48px;
      margin-bottom: 8px
    }
  }

  .corporate-lunch-features {
    margin-top: 1em;
    background-color: rgba(255, 255, 255, 0.6);
    border: none;

    .header {
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      line-height: 28.5px;
    }
  }

  .corporate-lunch-feature-item {
    margin: 2em 0;

    img {
      width: 35px;
      height: auto;
      margin-right: 1em;
    }

    span.title {
      font-size: 1.2em;
      font-weight: 500;
      vertical-align: bottom;
    }
  }

  .corporate-lunch-feature-item-2 {
    margin: 2em 0;

    img {
      width: 27px;
      height: auto;
      margin-right: 1em;
    }

    span.title {
      font-size: 1.2em;
      font-weight: 500;
      vertical-align: bottom;
    }
  }

  .feeding-hk-background {
    //margin-top: 10em;
    padding-bottom: 2em;
    background-image: url("/img/feedinghk-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100vw;
    min-height: 30vh;

    .feeding-hk-title {
      font-size: 18px;
      margin-bottom: 1em;
    }

    a {
      color: black !important;
      font-weight: 600;
    }

    img {
      margin-bottom: 2em;
      width: 70%;
      max-width: 350px;
    }
  }

  .signup-form-container {
    max-width: 400px;
    margin: 1em auto;
  }

  .testimonials-heading {
    color: $black-text-color;
    font-family: "Josefin Sans";
    font-size: 24px;
    margin-bottom: 16px;
  }

  .separator {
    margin: 1em;
    height: 1px;
    color: #979797;
  }

  .background-primary {
    background-color: $light-primary-color;
  }

  .panel {
    border-radius: $border-radius;

    .panel-heading {
      font-size: 20px;
      text-align: center;
      padding: 1em;
      background-color: white;
      border: none;
      font-weight: bold;

      .subtitle {
        font-size: 0.8em;
        color: $light-grey;
        font-weight: 400;
      }
    }
  }

  .testimonials-container {
    text-align: center;
    padding: 2em;
    img {
      margin-bottom: 1em;
    }
    .horizontal-separator {
      height: 3px;
      background-color: $primary-color;
      flex-basis: 10%;
      margin: 1em;
    }
  }

  .testimonial-text {
    color: $black-text-color;
    font-family: "Josefin Sans";
    font-size: 16px;
  }

  .features-sub-text {
    color: $black-text-color;
    font-family: "Josefin Sans";
    font-size: 1.1em;
    white-space: pre-wrap;
  }

  .features-container {
    text-align: center;
    padding: 2em;
    margin-top:2em;

    .horizontal-separator {
      height: 3px;
      background-color: $primary-color;
      flex-basis: 10%;
      margin: 1em;
    }
    .horizontal-separator-2 {
      height: 2px;
      background-color: black;
      flex-basis: 70%;
      margin: 2em;
    }   
  }

  .cap-text {
    font-size: 1.5em;
    display: inline-block;
  }

  .dot { 
    display: inline-block;
    height: 12px;
    width: 12px;
    border-radius: 6px;
    background-color: $primary-color;
    margin-right: 12px;
    margin-left: 12px;
  }

  .type-image-container { 
    background: $primary-color;
    display: flex;
    flex-direction: 'row';
    justify-content: space-evenly;
    align-self: 'center';
    padding-top: 16px;
    padding-bottom: 16px;

    .type-label { 
      text-align: center;
      font-size: 1.2em;
    }
  }

  .virtual-container {
    text-align: center;
    padding: 2em 2em 0;

    .horizontal-separator {
      height: 3px;
      background-color: $primary-color;
      flex-basis: 10%;
      margin: 1em;
    }
    .horizontal-separator-2 {
      height: 2px;
      background-color: black;
      flex-basis: 70%;
      margin: 2em;
    }
  }
  .features-container-2 {
    text-align: center;
    padding: 0em;
    &.mod-desktop{
      @media(max-width: 1100px) {
        display:none;
      }
    }
    &.mod-mobile{
      display:none;
      padding:0 45px;
      @media(max-width: 1100px) {
        display:block;
      }
    }
    // margin-top: -3em;
  }
  .virtual_logo {
    height: 14em;
    padding: 15px;
  }
  .virtual_logo_2 {
    height:9em;
    padding: 15px;
    @media(max-width: 1100px) {
      height:auto;
      width: 9em;
      max-width: 100%;
    }
  }
  .virtual_logo_3 {
    height:5em;
    padding: 10px;
  }
  .testimonial-img {
    height: 76px;
    width: auto;
    margin: 35px

  }

  .testimonial-img-factset {
    height: 31px;
    width: auto;
    margin: 20px
  }

  .testimonial-img-container {
    margin-bottom: 80px;
  }

  @media(max-width: 1000px) {
    .plate-left,
    .plate-right {
      height: 280px;
    }
  }

  @media(max-width: 768px) {
    .banner-container {
      .location-container {
        left: 24px;
        right: 24px;
      }  
    }
  }
}

@media(max-width: 768px) {
  #company-signup-container {
    .banner-container {
      background: url(/img/home-banner-1-mobile.jpg) no-repeat center center;
      background-size: cover;
      img {
        display:none;
      }
      .location-container {
        left: 24px;
        right: 24px;
      }  

      .title {
        font-size: 3rem;
      }
    }
  }
  
  .vertical-frame-left {
    border-right: 4px solid black;
  }

  .vertical-frame-right {
    border-left: 4px solid black;
  }
  
  .vertical-separator {
    display: none;
  }

  .company-signup-header-background {
    background-position: calc(100% - 50%) calc(100% + 0px) !important;
    height: 700px !important;
    align-items: flex-end !important;

    .container {
      background-color: rgba(0, 0, 0, 0.2);
      padding-bottom: 2em;
    }
  }

  .flex-center-row {
    .column-item-1 {
      order: 2;
    }

    .column-item-2 {
      order: 1;
    }
  }
  #customers-carousel {
    width: 100vw !important;
    margin-bottom: -6em;
    .rcs_sliderBox {
      width: 100% !important;
    }

    .rcs_arrowBtn {
      display: none;
    }
  }
  #testimonial-carousel {
    .rcs_arrowBtn {
     display: none;
    }
    .testimonial-card-container {
      width: 400px !important;
      padding-top:40px;
      height:350px !important;
      padding-left: 90px;
      padding-right: 90px;
    }
    .rcs_dotsSet {
      visibility: visible !important;
    }
    .rcs_sliderBox {
      height: auto !important;
    }

  }

  
  .company-signup-header-background {
    h1, h4 {
      text-align: center;
    }
  }
  .eat-smarter-container {
    .horizontal-separator {
      flex-basis: 15% !important;
    }
  }
  #company-signup-container {
    .panel {
      max-width: 380px;
      margin: 1em auto 0 auto;
    }

    .signup-steps-container {
      .row {
        padding-left: 5vw;
        padding-right: 5vw;
      }
    }
  }
}

@media(max-width: 990px) {
  #testimonial-carousel {
    .rcs_sliderBox {
      width: 100% !important;
      height: auto !important;
    }
    .rcs_arrowBtn {
      background-color: $primary-color !important;
      width: 30px !important;
      height: 30px !important;
      margin: 0 !important;
    }
    .itemWrapper {
      background-color: transparent !important;
    }
    .testimonial-card-container {
      padding-top:40px;
      width:550px;
    }
  }
  .flex-center-row {
    .column-item-1 {
      order: 2;
    }

    .column-item-2 {
      order: 1;
    }
  }
}

.feedingXnosh {
  margin-bottom: 2em;
  width: 70%;
  max-width: 350px;
  padding-top: 30px !important;
}

.cs-img-row {
  padding: 12px 0px 12px 0px;
  display: flex;
  flex-direction: row;
  background: $primary-color;
  
  .cs-img-col {
    padding: 24px;
    
    h4 {
      text-align: center;
      font-family: $second-font;
      color: #6d6e71;
    }
  }
}

.swiper-custom-arrow-right{
  position:relative;
  $size:30px;
  width:$size;
  height:$size;
  border-radius: 100%;
  background:#FFF;
  margin:1.5rem auto;
  display:flex;
  align-items: center;
  justify-content: center;
  &:before{
      position:relative;
      content:'';
      display:block;
      $size:35%;
      width:$size;
      height:$size;
      border-top:2px solid $primary-color;
      border-left:2px solid $primary-color;
      transform: rotate(135deg);
      transform-origin: center center;
      left:-7.5%;
  }
}
.swiper-custom-arrow-left{
  position:relative;
  $size:30px;
  width:$size;
  height:$size;
  border-radius: 100%;
  background:#FFF;
  margin:1.5rem auto;
  display:flex;
  align-items: center;
  justify-content: center;
  &:before{
      position:relative;
      content:'';
      display:block;
      $size:35%;
      width:$size;
      height:$size;
      border-top:2px solid $primary-color;
      border-left:2px solid $primary-color;
      transform: rotate(-45deg);
      transform-origin: center center;
      left:-7.5%;
  }
}
.prev-el {
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
  cursor: pointer;
}
.next-el {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  cursor: pointer;
}

.features-container-2-swiper .swiper-wrapper{
  align-items: center;
}
#company-signup-container{
  .section-app{
    background:$primary-color;
    margin-top:60px;
    display:flex;
    align-items: center;
    justify-content: center;
    padding:0 10%;
  }
  .section-app-wrapper{
    display:flex;
    align-items: center;
    justify-content: center;
    width:100%;
    max-width:1024px;
    @media(max-width: 768px) {
      display:block;
    }
  }
  
  .section-app-download{
    width:50%;
    @media(max-width: 768px) {
      padding:80px 0 40px;
      width:100%;
    }
    @media(max-width: 488px) {
      padding:60px 0 15px;
    }
    .download-app{
      margin-top:0;
    }
  }
  
  .section-app-img{
    padding-top:60px;
    width:50%;
    @media(max-width: 768px) {
      width:65%;
      margin:0 auto;
      padding:0;
    }
  }
}