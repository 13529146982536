@import "../../../variables.scss";

#order-summary {
  margin-top: 2em;
  margin-bottom: 2em;

  .feeding-hk.checkbox {
    label:first-child {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
    }
    img {
      margin-right: 1em;
    }
  }

  .paragraph {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px;
    color: $light-grey;
    text-align: center;
  }

  .panel {
    border-radius: $border-radius;
  }
  .panel-heading {
    background-color: white;
    border-radius: $border-radius;
    color: black;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    font-weight: 600;
  }

  .list-group-item {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px;
    display: flex;
    flex-direction: column;

    &.nutrition-info-container {
      font-size: 13px;
    }
  }
  .roboto-condensed {
    font-family: "Roboto Condensed";
  }

  .right-align {
    text-align: right;
  }

  .vertical-spacing {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .primary-color {
    color: $primary-color;
  }

  .light-primary-color {
    color: $light-primary-color;
  }

  .cart-item-date {
    color: #000000;
    font-weight: 600;
  }

  .cart-item-name {
    flex: 1;
    margin: 0 24px;
    text-align: justify;

    @media screen and (max-width: 375px) {
      font-size: 14px;
      margin: 0 14px;
    }
    @media screen and (max-width: 320px) {
      font-size: 12px;
      margin: 0 12px;
    }
  }

  .cart-item-quantity {
    @media screen and (max-width: 320px) {
      font-size: 14px;
    }
  }

  .real-price {
    @media screen and (max-width: 375px) {
      font-size: 14px;
    }
    @media screen and (max-width: 320px) {
      font-size: 12px;
    }
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  label {
    font-weight: normal !important;
    color: $light-grey;
    font-size: 14px;
  }
  .value-text {
    color: $darker-grey;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px;
    font-weight: normal;
  }

  select,
  input {
    display: block;
    border-radius: 0;
    border: 0.5px solid $lighter-grey;
    width: 100%;
    margin-bottom: 15px;
    padding: 16px 16px;
    color: $matterhorn;
  }

  label {
    font-size: 1.4rem;
    color: $light-grey;
  }

  .coins-remaining {
    font-size: 1.4rem;
    color: black;
    font-family: 'Josefin Sans', sans-serif;
  }

  .coin-image {
    height: 18px;
    width: 18px;
    margin-right: 8px;;
  }

  .error-message {
    width: 100%;
    color: red;
    margin-bottom: .5rem;
    font-size: 1rem;
    float: left;
  }

  .action-btn-container {
    flex: 1;
    margin-top: 1em;
    margin-bottom: 1em;

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Josefin Sans', sans-serif;
      width: 100%;
      height: 52px;
      border: none;
      border-radius: $border-radius;
      background-color: $primary-color;
      font-size: 16px;
      color: black;
      img {
        margin-left: 1em;
      }
    }
  }

  .no-border {
    border: none;
  }

  .margin-top {
    margin-top: 1em;
  }

  .big-title {
    font-size: 26px;
    font-weight: 600;
  }

  .flex {
    display: flex;
  }

  .margin-right {
    margin-right: 1em;
  }

  .strikethough-text{
    text-align: left;
    text-decoration: line-through;
    margin-right: 1em;
    color:$light-grey;
  }

  .payment-option-selector {
    flex: 1;
    min-width: 170px;
    background-color: white;
    padding-top: 1em;
    padding-bottom: 1em;
    border: solid 1px $light-grey;
    color: $darker-grey;
    margin-bottom: 1em;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.selected {
      border: solid 2px $primary-color;
      color: $light-primary-color;
      background: $primary-color;
    }

    img {
      width: 18px;
      margin-right: 20px;
    }
  }

  .heading-text {
    border-bottom: 2px solid $primary-color;
  }

  .surcharge-label {
    flex: 1;
    color: #707070;
    font-family: 'Josefin Sans', sans-serif;
  }

  .surcharge {
    color:#707070;
    font-family: 'Josefin Sans', sans-serif;
  }

  .btn-info{
    $link: '/img/icon-info.png';
    display:inline-block;
    vertical-align: middle;
    appearance: none;
    background: url() no-repeat center center;
    background-size: contain;
    padding:0;
    border:0;
    $size:20px;
    width:$size;
    height:$size;
    margin-top:-5px;
    span{
      font-size: 0;
    }
  }
}

.force-hidden{
  display:none !important;
}