@import "../../variables.scss";

$nosh-hover-color: #63bbb0;
$nosh-light-grey: #a6a6a5;

$nosh-asian-brand-color: #e65b43;
$nosh-asian-brand-text-color: #e95a43;

$main-title-fs: 40px;
$main-title-fs-res: 38px;
$main-subtitle-fs: 36px;
$main-subtitle-fs-res: 32px;
$desc-fs: 22px;
$desc-fs-res: 18px;
$desc-fs-normal: 16px;
$tiny-text: 15px;
$menubar-fs: 12px;
$menubar-small-fs: 10px;
$menubar-large-fs: 18px;
$footer-fs: 14px;
$footer-small-fs: 10px;

$bebas: "BebasNeue";
$jose: "Josefin Sans";
$mont-light: "Montserrat-light";
$mont-semibold: "Montserrat-semibold";
$koz-reg: "Koz-Regular";

$grey-border: #e2e2e2;
$grey-darker: #bebebe;
$grey-white: #fafafa;

$main-font: "Josefin Sans", sans-serif;
$second-font: "Bebas Neue";

@font-face {
  font-family: 'Bebas Neue';
  src: url('/fonts/BebasNeueRegular.ttf');
}

a:link,
a:visited {
  color: $primary-color;
  text-decoration: none;
}
a:hover,
a:active {
  color: $primary-color;
  text-decoration: underline;
}

.nosh-mp-btn {
  font-family: Josefin Sans,sans-serif;
  font-size: 18px;
  border-style: none;
  background-color: $primary-color;
  color: #fff;
  border-radius: 0;
  outline: none;
  padding: 10px 0;
  -webkit-transition: all .2s linear;
  -o-transition: all .2s linear;
  transition: all .2s linear;
  cursor: pointer;
  width: 157px;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
}

.nosh-btn {
  font-family: "Josefin Sans", sans-serif;
  /* border-style: none; */
  background-color: $primary-color;
  color: white;
  border-radius: 0px;
  font-size: 16px;
  // margin-top: 30px;
  outline: none;
  padding: 18px 24px;
  padding-top: 20px;
  padding-bottom: 16px;
  transition: all 0.2s linear;
  cursor: pointer;
  border-color: #fff;
  border-width: 0px 1px 0px 1px;
  border-style: solid;

  &:hover {
    background-color: #63bbb0;
  }

  &:active {
    background-color: #55a79d;
  }

  a {
    color: #fff;
    text-decoration: none;
  }
}

.nosh-long-btn {
  font-family: "Josefin Sans", sans-serif;
  border-style: none;
  background-color: $primary-color;
  color: white;
  border-radius: 2px;
  font-size: 16px;
  outline: none;
  padding: 15px 176px;
  transition: all 0.2s linear;
  cursor: pointer;

  &:hover,
  &:active {
    background-color: #63bbb0;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  @media screen and (max-width: 768px) {
    padding: 10px 95px;
  }
}

//media query loop
@mixin mQ($arg...) {
  @if length($arg) ==1 {
    @media screen and (min-width: nth($arg,1)) {
      @content;
    }
  }
  @if length($arg) ==2 {
    @media screen and (min-width: nth($arg,1)) and(max-width:nth($arg,2)) {
      @content;
    }
  }
}

$desktop: "(max-width: 1199px)";
$mobile: "(max-width: 480px)";

@media screen and (max-width: 480px) {
  .mobile-hidden {
    display: none !important;
  }
}

.catering-container {
  width: 100%;
  padding-bottom: 10px;
  font-family: "Josefin Sans", sans-serif;
  color: #4a4a4a;

  .cs-img-row {
    display: flex;
    flex-direction: row;
    max-width: 1000px;
    margin: auto;

    .cs-img-col {
      display: flex;
      flex-direction: column;
      width: 33%;
      padding: 20px;

      @media screen and (max-width: 800px) {
        width: 100%;
        display: inline-block;
        padding: 20px 0;
      }
    }
    @media screen and (max-width: 800px) {
      width: 100%;
      flex-direction: column;
      text-align: center;
    }
  }

  .tm-content-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1920px) {
      min-height: 850px;
    }

    @media screen and (max-width: 1440px) {
      min-height: 650px;
    }
    /* Banner sec */
    .tm-header-box {
      width: 100%;

      .tm-info-flex {
        width: 80%;
        margin-top: 6%;

        .info-icon-box {
          width: 21%;
          float: left;
          padding-left: 15px;
          padding-right: 15px;
          position: relative;

          img {
            width: 100px;
          }

          .icon-text {
            font-family: $mont-semibold;
            font-size: 14px;
            color: #6d6e71;
          }
        }
      }

      .tm-title {
        font-size: $main-title-fs;
        padding-bottom: 20px;

        @media screen and (max-width: 768px) {
          font-size: $main-title-fs-res;
        }

        .tm-title-h1 {
          font-size: 45px;
          font-weight: 400;
          margin-bottom: 0;
          padding-bottom: 0;
          margin-top: 0;
          font-weight: 600;
          font-family: $bebas;
          color: $primary-color;
          @media screen and (max-width: 768px) {
            font-size: 38px;
          }
        }
      }

      .tm-des-1 {
        text-align: center;
        font-size: 26px;
        font-family: $mont-light;
        color: #6e6f72;
      }

      .tm-des-2 {
        text-align: center;
        font-size: $desc-fs;
        padding-bottom: 20px;
      }

      .tm-menu-dl {
        margin-top: 40px;
      }
    }

    /* Weekly meal plan sec */
    .tm-middle-box {
      background-color: rgba(255, 255, 255, 0.9);
      // padding: 70px 100px;
      width: 100%;
      margin: 0 0 50px;

      @media screen and (max-width: 768px) {
        padding: 100px 35px;
      }

      .Meal-category {
        color: red;
      }

      .mp-btn {
        width: 300px;
        margin-right: 20px;
        border: 1px solid $primary-color;
      }

      .cs-btn {
        width: 300px;
        background-color: inherit;
        border: 1px solid $primary-color;
        color: $primary-color;
        font-family: $jose;
        border-radius: 2px;
        padding: 20px 24px 16px;
        cursor: pointer;
        transition: all 0.2s linear;
        font-size: 16px;
        text-decoration: none !important;
        outline: none;

        &:hover {
          background: $nosh-hover-color;

          .cor-email {
            text-decoration: none !important;
          }
        }
      }

      .weeks {
        background: $nosh-light-grey;
        margin-bottom: 50px;

        ul {
          padding-left: 0;
          margin: 0;
        }

        .nosh-btn {
          display: inline-block;
          padding: 18px 65px;
          background: $nosh-light-grey;
          font-family: $bebas;
          font-size: 16px;
          font-weight: 500;

          &:hover {
            background: #63bbb0;
          }
        }

        .active {
          position: relative;
          background: #587d63;

          &:hover::after {
            border-top-color: #63bbb0;
          }
        }

        .active:after {
          top: 100%;
          left: 57%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        .active:after {
          border-color: rgba(88, 125, 99, 0);
          border-top-color: #587d63;
          border-width: 15px;
          margin-left: -30px;
        }
      }

      .weekly-colm {
        width: 65%;
        margin: auto;

        .weekly-meals {
          clear: both;
          float: left;
          border-bottom: 1px solid #c3c2c2;
          margin-bottom: 30px;
          padding-bottom: 10px;
          position: relative;
          width: 100%;

          // &:last-child {
          //     border-bottom: none;
          // }

          .meal-date,
          .meal-day {
            width: 100%;
            text-align: left;
          }

          .meal-date {
            color: $nosh-light-grey;
            font-family: $koz-reg;
            text-transform: uppercase;
            font-size: 13px;
          }

          .meal-day {
            font-family: $bebas;
            font-size: $main-subtitle-fs-res;
            font-weight: 600;
            color: $primary-color;
            margin-bottom: -11px;

            &.active {
              color: $nosh-asian-brand-text-color;
            }
          }

          .meal-colm {
            width: 100%;
            float: left;

            .weekly-plan {
              border-bottom: 1px solid #afaeae;
              float: left;
              position: relative;
              margin-bottom: 25px;
              padding-bottom: 15px;
            }
          }

          .meal-type {
            text-align: left;
            margin-top: 30px;
            font-family: $koz-reg;
            color: $nosh-light-grey;
            font-size: 12px;
            margin-bottom: -12px;
          }

          .meal-dish-details {
            text-align: left;

            .meal-dish-name {
              color: #6d6e71;
              font-family: $mont-semibold;
              float: left;
              width: 45%;
              padding-right: 5%;
            }
          }

          .dish-calories-details {
            float: left;
            width: 50%;
            text-align: right;
            ul {
              padding-left: 0;

              li {
                display: inline-block;
                font-family: $bebas;
                border-right: 1px solid #ddd;
                padding: 0 20px;

                &:last-child {
                  border-right: none;
                }

                &:first-child {
                  padding-left: 0;
                }

                span {
                  color: $primary-color;
                  font-size: 20px;
                  font-weight: 600;
                }

                span.dish-data {
                  display: block;
                  font-weight: 400;
                  font-size: 30px;
                  color: rgba(77, 77, 77, 0.75);
                }
              }
            }
            &.active {
              ul {
                li {
                  span:not(.dish-data) {
                    color: $nosh-asian-brand-text-color;
                  }
                }
              }
            }
          }

          &:nth-child(2) img {
            width: 30%;
            position: absolute;
            bottom: 45%;
            transform: rotate(11deg);
          }

          &:nth-child(3) img {
            height: 432px;
            width: 50%;
            position: absolute;
            right: 98%;
            top: -18%;
          }

          &:nth-child(4) img {
            height: 250px;
            width: 24%;
            position: absolute;
            left: 99%;
            bottom: 50%;
            transform: rotate(2deg);
          }
        }
      }
    }
    /* order weekly sec */
    .tm-order-weekly-details {
      /* background: url("/img/teammealplan/Western-High-Protein.png") no-repeat center center; */
      background-position: 57% 38%;
      min-height: 500px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .tm-order-weekly-box {
        padding: 40px 0 60px;
        background: #fff;

        .learn-more {
          font-family: $mont-light;
          font-size: $desc-fs-res;
        }
      }

      .order-weekly-title {
        color: $primary-color;
        font-size: $main-title-fs-res;
        font-weight: 600;
        font-family: BebasNeue;
      }

      .order-weekly-desc {
        padding: 0 24%;
        margin-bottom: 80px;
        margin-top: 15px;
        line-height: 25px;
        font-size: 17px;
        color: #6d6e71;
        font-family: $mont-light;
      }

      .nosh-btn {
        padding: 18px 80px;
      }
      .download-menu {
        padding: 18px 30px;
      }
    }
    /* Event Details */
    .event-details {
      padding: 50px 0;

      h2 {
        color: $primary-color;
        font-size: $main-title-fs-res;
        font-weight: 600;
        font-family: $bebas;
        margin-bottom: 10px;
      }

      .event-msg {
        margin-bottom: 20px;
        margin-top: 15px;
        line-height: 25px;
        color: #6d6e71;
        font-family: $mont-light;

        span {
          display: block;
        }
      }

      .cs-img-row {
        .cs-img-col {
          h4 {
            font-family: $second-font;
            color: #6d6e71;
          }
        }
      }
    }
  }
  .weeks {
    background: $nosh-light-grey;
    margin-bottom: 50px;
    ul {
      padding-left: 0px;
      margin: 0px;
    }
    .nosh-btn {
      display: inline-block;
      padding: 18px 65px;
      background: $nosh-light-grey;
      font-family: $bebas;
      font-size: 16px;
      font-weight: 500;
      &:hover {
        background: #63bbb0;
      }
    }
    .active {
      position: relative;
      background: #587d63;
      &:hover::after {
        border-top-color: #63bbb0;
      }
    }
    .active:after {
      top: 100%;
      left: 57%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    .active:after {
      border-color: rgba(88, 125, 99, 0);
      border-top-color: #587d63;
      border-width: 15px;
      margin-left: -30px;
    }
  }
  .weekly-colm {
    width: 65%;
    margin: auto;
    .weekly-meals {
      clear: both;
      float: left;
      border-bottom: 1px solid #c3c2c2;
      margin-bottom: 30px;
      padding-bottom: 10px;
      position: relative;
      // &:last-child{border-bottom:none;}
      .meal-day,
      .meal-date {
        width: 100%;
        text-align: left;
      }
      .meal-date {
        color: $nosh-light-grey;
        font-family: $koz-reg;
        text-transform: uppercase;
        font-size: 13px;
      }
      .meal-day {
        font-family: $bebas;
        font-size: $main-subtitle-fs-res;
        font-weight: 600;
        color: $primary-color;
        margin-bottom: -11px;
      }
      .meal-colm {
        width: 100%;
        float: left;
        .weekly-plan {
          border-bottom: 1px solid #afaeae;
          float: left;
          position: relative;
          margin-bottom: 25px;
          padding-bottom: 15px;
        }
      }
      .meal-type {
        text-align: left;
        margin-top: 30px;
        font-family: $koz-reg;
        color: $nosh-light-grey;
        font-size: 12px;
        margin-bottom: -12px;
      }
      .meal-dish-details {
        text-align: left;
        .meal-dish-name {
          color: #6d6e71;
          font-family: $mont-semibold;
          float: left;
          width: 45%;
          padding-right: 5%;
        }
      }
      .dish-calories-details {
        float: left;
        width: 50%;
        ul {
          padding-left: 0px;
          li {
            display: inline-block;
            font-family: $bebas;
            color: $primary-color;
            border-right: 1px solid #ddd;
            padding: 0px 20px;
            font-weight: 600;
            font-size: 20px;
            &:last-child {
              border-right: none;
            }
            &:first-child {
              padding-left: 0px;
            }
            span:not(.dish-data) {
              display: block;
              font-weight: 400;
              font-size: 30px;
              color: rgba(77, 77, 77, 0.75);
            }
          }
        }
      }
      &:nth-child(2) img {
        height: 240px;
        width: 30%;
        position: absolute;
        left: 92%;
        bottom: 62%;
        transform: rotate(11deg);
      }
      &:nth-child(3) img {
        height: 432px;
        width: 50%;
        position: absolute;
        right: 98%;
        top: -18%;
      }
      &:nth-child(4) img {
        height: 250px;
        width: 24%;
        position: absolute;
        left: 99%;
        bottom: 50%;
        transform: rotate(2deg);
      }
    }
  }
  .orderNow {
    .feeling-hungry {
      font-family: BebasNeue;
      font-size: 30px;
      letter-spacing: 2px;
      font-weight: 600;
      color: $primary-color;
      padding-bottom: 25px;
    }
    .order-now-btn {
      padding: 15px 65px;
      font-size: 22px;
    }
  }

  //ga brand task up-to-date.
  .select-button {
    border-radius: 30px;
    padding: 10px;
    width: 150px;
    background-color: transparent;
    background-repeat: no-repeat;

    &:active,
    &:focus {
      outline: 0;
    }

    @media #{$mobile} {
      border-radius: 25px;
      padding: 8px;
      width: 125px;
    }
  }

  .content-logo-block {
    width: 100%;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 15px 0;

    .title {
      width: 100%;
      margin: 20px auto 10px;
      color: $primary-color;
      font-family: BebasNeue;
      font-size: 32px;
      font-weight: 600;

      &.active {
        color: $nosh-asian-brand-text-color;
      }
    }

    .sub-title {
      width: 100%;
      margin-bottom: 15px;
    }

    .corporate-ga-logo {
      width: 160px;
      margin: auto;
    }

    .corporate-nosh-logo {
      width: 225px;
      margin: auto;
    }
  }
}

/* Media query */
@include mQ(1920px) {
  /* Weekly meal plan */
  .catering-container {

    .tm-content-box {
      .tm-middle-box {
        padding: 0px 0px 60px;
        margin: 0px;
        .weeks {
          .nosh-btn {
            padding: 18px 105px;
          }
        }
        .weekly-colm {
          width: 58%;
          .weekly-meals {
            float: left;
            margin: 0 0 0 4%;
            width: 100%;
          }
        }
      }
      /* Event Details */
      .event-details {
        .event-msg {
          padding: 0px 24%;
          font-size: 17px;
        }
      }
    }
  }
}
@include mQ(1400px) {
  .catering-container {
    .tm-content-box {
      .tm-middle-box {
        .weeks {
          .nosh-btn {
            padding: 18px 90px;
          }
        }
      }
    }
  }
}
@include mQ(1200px) {
  .catering-container {
    .tm-content-box {
      .tm-order-weekly-details {
        background: url("/img/teammealplan/Western-High-Protein.png") no-repeat
          center center;
        background-size: 175%;
      }

      .tm-header-box {
        .tm-info-flex {
          margin: 3% auto 0 13%;
        }
      }

      .event-details {
        .event-msg {
          padding: 0 24%;
        }
      }
    }
  }
}
@media #{$desktop} {
  .tm-content-box {
    .tm-order-weekly-details {
      background: url("/img/teammealplan/Western-High-Protein-mob.png")
        no-repeat center center;
      background-size: auto;
    }
  }
}

@include mQ(992px, 1199px) {
  .catering-container {
    .tm-content-box {
      /* Banner screen */
      .tm-header-box {
        min-height: 500px;
        .tm-info-flex {
          width: 100%;
          margin-top: 8%;
        }
        .tm-des-1 {
          padding: 0px 10px;
        }
      }
      /* Weekly meal plan */
      .tm-middle-box {
        padding: 0px 0px 30px;
        margin: 0px;
        .weeks {
          .nosh-btn {
            border: none;
            padding: 18px 65px;
            &.active {
              &:after {
                left: 55%;
              }
            }
          }
        }
        .weekly-colm {
          width: 80%;
          .weekly-meals {
            .meal-colm {
              width: 100%;
            }
            .meal-dish-details {
              .meal-dish-name {
                padding-right: 0px;
                margin-bottom: 30px;
              }
            }
            .dish-calories-details {
              float: left;
              width: 50%;
              ul {
                li {
                  padding: 0px 10px;
                }
              }
            }
            &:nth-child(2) img {
              height: 300px;
              width: 35%;
              left: 74%;
              bottom: 127%;
            }
            &:nth-child(3) img {
              height: 250px;
              width: 42%;
              left: 68%;
              top: -5%;
            }
            &:nth-child(4) img {
              height: 150px;
              width: 24%;
              left: 79%;
              bottom: 78%;
            }
          }
        }
      }

      /* order weekly details */
      .tm-order-weekly-details {
        .order-weekly-desc {
          padding: 0px 10%;
        }
        .nosh-btn {
          padding: 18px 60px;
        }
        .tm-order-weekly-box {
          margin: 10px 10%;
        }
      }
      /* Event details */
      .event-details {
        padding: 20px 10%;
        .event-msg {
        }
      }
    }
  }
}
@include mQ(768px, 991px) {
  .catering-container {
    .tm-content-box {
      /* Banner screen */
      .tm-header-box {
        min-height: 600px;
        .tm-info-flex {
          width: 100%;
          margin-top: 8%;
          .info-icon-box {
            width: 45%;
            float: left;
            padding-left: 15px;
            padding-right: 15px;
            position: relative;
            &:nth-child(3) {
              margin-top: 45px;
            }
            &:nth-child(4) {
              margin-top: 45px;
            }
          }
        }
        .tm-des-1 {
          padding: 0px 10px;
        }
      }
      /* Weekly meal plan */
      .tm-middle-box {
        padding: 0px 0px 30px;
        margin: 0px;
        .weeks {
          .nosh-btn {
            border: none;
            padding: 18px 40px;
            border-bottom: none;
            &.active {
              &:after {
                left: 55%;
              }
            }
          }
        }
        .weekly-colm {
          width: 80%;
          .weekly-meals {
            .meal-colm {
              width: 100%;
            }
            .meal-dish-details {
              .meal-dish-name {
                padding-right: 0px;
                margin-bottom: 30px;
              }
            }
            .dish-calories-details {
              float: left;
              width: 50%;
              ul {
                li {
                  padding: 0px 10px;
                }
              }
            }
            &:nth-child(2) img {
              height: 300px;
              width: 35%;
              left: 74%;
              bottom: 127%;
            }
            &:nth-child(3) img {
              height: 250px;
              width: 42%;
              left: 68%;
              top: -5%;
            }
            &:nth-child(4) img {
              height: 150px;
              width: 24%;
              left: 79%;
              bottom: 78%;
            }
          }
        }
      }

      /* order weekly details */
      .tm-order-weekly-details {
        .order-weekly-desc {
          padding: 0px 10%;
        }
        .nosh-btn {
          padding: 18px 60px;
        }
        .tm-order-weekly-box {
          margin: 10px 10%;
        }
      }
      /* Event details */
      .event-details {
        padding: 20px 10%;
        .event-msg {
        }
      }
    }
  }
}
@include mQ(320px, 767px) {
  .catering-container {
    .tm-content-box {
      /* Banner screen */
      .tm-header-box {
        .tm-info-flex {
          width: 100%;
          margin-top: 10%;
          .info-icon-box {
            width: 40%;
            float: left;
            padding-left: 15px;
            padding-right: 15px;
            position: relative;
            &:nth-child(3) {
              margin-top: 25px;
            }
            &:nth-child(4) {
              margin-top: 25px;
            }
          }
        }
        .tm-des-1 {
          padding: 0px 10px;
        }
      }
      /* Weekly meal plan */
      .tm-middle-box {
        padding: 0px 0px 10px;
        margin: 0px;
        .weeks {
          .nosh-btn {
            border: none;
            width: 100%;
            padding: 18px 0px;
            border-bottom: 1px solid #fff;
            &.active {
              &:after {
                left: 55%;
              }
            }
          }
          select {
            font-family: $bebas;
            font-size: $desc-fs-res;
            font-weight: 500;
            padding: 18px 18px 18px 30px;
            width: 100%;
            color: #fff;
            background: $primary-color url("/img/teammealplan/down_icon.png")
              no-repeat center;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-position-x: 90%;
            background-size: 8%;
          }
        }
        .weekly-colm {
          width: 80%;
          .weekly-meals {
            .meal-colm {
              width: 100%;
            }
            .meal-dish-details {
              .meal-dish-name {
                width: 90%;
              }
            }
            .dish-calories-details {
              width: 90%;
              text-align: left;
              ul {
                li {
                  padding: 0px 10px;
                }
              }
            }
            &:nth-child(2) img {
              height: 150px;
              width: 60%;
              left: 45%;
              bottom: 185%;
              display: none;
            }
            &:nth-child(3) img {
              height: 160px;
              width: 60%;
              left: 50%;
              top: -5%;
              display: none;
            }
            &:nth-child(4) img {
              height: 150px;
              width: 50%;
              left: 55%;
              bottom: 78%;
              display: none;
            }
          }
        }
      }

      /* order weekly details */
      .tm-order-weekly-details {
        min-height: 600px;
        .order-weekly-desc {
          padding: 0px 5%;
        }
        .nosh-btn {
          padding: 18px 60px;
        }
        .tm-order-weekly-box {
          margin: 10px 10%;
        }
      }
      /* Event details */
      .event-details {
        padding: 20px 10%;
        .event-msg {
        }
      }
    }
    .orderNow {
      .feeling-hungry {
        padding-bottom: 15px;
      }
    }
  }
}

.event-intro-section{
  width:100%;
  height:100%;

  .event-intro-container{
    background: url(/img/teammealplan/nosh-catering-hero.jpg) no-repeat center center;
    width: 100%;
    height: 100%;
    background-size: cover;
    padding-top: 150px;
    padding-bottom: 150px;
    opacity: 0.9;
    

    .event-intro-box{
      padding:30px;
      background-color: $primary-color;
      color:#fff;
      max-width: 400px;
      margin: 0 auto;
      box-sizing: unset;

      .event-intro-title{
        font-size: 40px;
        font-family: $second-font;
        text-align: center;
      }
      .event-intro-desc{
        font-weight: 400;
        line-height: 30px;
        margin:10px 0 10px 0;
        text-align: center;
      }
      a:hover, a:active{
        text-decoration: none;
      }
      .contactus-button{
        color: $primary-color;
        background-color:#fff;
        margin: 0 auto;
        margin-top: 10px;
        font-weight: 400;
      }
    }
    
  }
}

.see-menu-section{
  width:100%;
  height:100%;

  .see-menu-container{
    background: url(/img/teammealplan/seeourmenu.jpg) no-repeat center center;
    width: 100%;
    height: 100%;
    background-size: cover;
    padding-top: 150px;
    padding-bottom: 150px;

    @media screen and (max-width:768px){
      background: url(/img/teammealplan/cateringmenu-bg.jpg) no-repeat center center;
      background-size: cover;
      padding-bottom: 260px;
      padding-top: 50px;
  }

    .see-menu-box{
      text-align: left;
      padding:30px;
      max-width: 400px;
      margin-left:15%;

      @media screen and (max-width:768px){
        margin: 0 auto;
        text-align: center;
      }

      .see-menu-title{
        color:$primary-color;
        font-size:40px;
        font-family: $second-font;
      }

      .see-menu-desc{
        font-weight: 400;
        line-height: 30px;
        margin:10px 0 10px 0;
      }

      .see-menu-buttons-wrap{
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        a {
          flex: 1;
        }
      }

      .see-menu-button{
        margin-top: 10px;
        margin-left:0px;
        font-weight: 400;
        padding: 10px;

        @media screen and (max-width:768px){
          margin: 0 auto;
        }
  
      }
    }
    
  }
}

.our-brand-section{
  width:100%;
  height:100%;

  .our-brand-container{
    background: url(/img/teammealplan/nosh-ga.jpg) no-repeat center center;
    width: 100%;
    height: 100%;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 100px;
    background-position: 50% 30%;
	  background-size: cover;

    @media screen and (max-width:768px){
      background: url(/img/teammealplan/nosh-ga-mobile.jpg) no-repeat center center;
      background-size: cover;
    }

    .our-brand-box{
      padding:30px;
      max-width: 400px;
      margin: 0 auto;

      .our-brand-title{
        color:$primary-color;
        font-size:40px;
        font-family: $second-font;
      }

      .our-brand-desc{
        font-weight: 400;
        line-height: 30px;
        margin:10px 0 10px 0;
      }

      .our-brand-button{
        margin: 0 auto;
        margin-top: 10px;
        font-weight: 400;
      }
    }
    
  }
}