@import "../../../../variables.scss";

.modal-content {

  .meal-details-left-col {
    width: 55%
  }

  .meal-details-right-col {
    width: 45%
  }

  border-radius: $border-radius;

  .max-quantity-text {
    color: $red;
    font-family: 'Josefin Sans', sans-serif;
    margin-top: 0.5em;
  }

  .text {
    font-family: 'Josefin Sans', sans-serif;
    color: $black-text-color;
  }

  .title {
    margin-top: 1em;
    font-size: 16px;
  }

  .secondary-text {
    color: $light-grey;
    font-size: 12px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: lighter;
    margin-right: 1em;
  }

  .nutrition-container {
    margin-top: 1em;
    margin-bottom: 1em;
    display: flex;
    flex: 1;
    flex-direction: row;
  }

  .recipes-container {
    margin-top: -1em;
    margin-bottom: 1em;
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 20px;
      height: 20px;
      margin-right: 0.5em;
    }
    .label {
      padding: 0.35em;
      padding-top: 0.5em;
      font-size: 0.8em;
      font-weight: 400;
    }
  }

  .cuisine-type-image {
    height: 24px;
    position: absolute;
    top: 1em;
    left: 1em;
  }

  .selected-btn {
    border-bottom: solid 2px $primary-color;
    width: 45%;
    margin-left: 1em;
    color: $light-primary-color;
    background-color: transparent;
    font-size: 14px;
    height: 20px;
  }

  .unselected-btn {
    color: $black-text-color;
    background-color: transparent;
    font-size: 14px;
    height: 20px;
  }

  .description-container {
    margin-bottom: 2em;
  }

  .description-text {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 14px;
    color: $dark-grey;
    margin-left: 1em;
    margin-top: 1em;
  }

  .ratings-container {
    display: flex;

    .star-icon {
      width: 20px;
      height: 20px;
      margin-right: 0.5em;
      
    }
    .ratings-amount {
      font-family: 'Josefin Sans', sans-serif;
      font-size: 12px;
      color: $light-grey;
    }
  }

  .separator {
    height: 1px;
    background-color: $lighter-grey;
    margin: 1em 0;
  }

  .quantity-container {
    display: flex;
    align-items: center;

    .text {
      margin-right: 1em;
    }
    .btn {
      margin-right: 1em;
      border-radius: $border-radius;
      background-color: $primary-color;
      color: black;
      font-family: 'Josefin Sans', sans-serif;
      font-size: 14px;
      border: none;
    }
  }

  .close-btn:hover {
    background-color: transparent;
  }

  .close-btn:focus {
    background-color: transparent;
  }

  .close-btn-container {
    width: 100%;
    text-align: right;
    margin-bottom: -1em;
  }

  .close-btn {
    margin-top: -20px;
    margin-right: -20px;
    border: none;
    width: 50px;
    height: 50px;
    padding: 0;
    background-size: 40px;
    background-color: transparent;
    background-image: url("/img/close-icon.png");
    background-repeat:   no-repeat;
    background-position: top left;
  }

}

@media(max-width: 540px) {
  .modal-content {
    margin-top: 2em !important;
    .meal-details-left-col {
      width: 100% !important;
      padding-left: 1em;
    }

    .meal-details-right-col {
      width: 100% !important
    }

    .quantity-container {
      padding-left: 1em;
    }



  }

}

