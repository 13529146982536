@import "../../../../variables.scss";

.meal-card.panel {
  border-radius: $border-radius;
  position: relative;
  height: 93%;

  &.mod-catering{
    padding-bottom:3rem;
  }

  .meal-image-holder{
    min-height:80px;
  }
  
  .meal-image {
    width: 100%;
    height: auto;
    // background-size: cover !important;
    // background-position: center !important;
    cursor: pointer;
  }

  .text {
    font-family: 'Josefin Sans', sans-serif;
  }

  .primary-text {
    font-size: 16px;
    color: $black-text-color
  }

  .secondary-text {
    color: $light-grey;
    font-size: 12px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: lighter;
    margin-right: 1em;
  }

  .nutrition-container {
    margin-top: 1em;
    margin-bottom: 1em;
    display: flex;
    flex: 1;
    flex-direction: row;
  }

  .meal-temperature-container {
    .label {
      padding: 0.35em;
      padding-top: 0.5em;
      font-size: 0.8em;
      font-weight: 400;
    }
  }

  .cuisine-type-image {
    height: 42px;
    position: absolute;
    top: 1em;
    left: 1em;
  }

  .cuisine-image {
    position: absolute;
    top: 8px;
    left: 8px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    height: auto;
    width: auto;
    max-height: 60px;
    max-width: 120px;
  }
  .image-cropper {
    position: relative;
    float: right
  }

  .bottom-content-container {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
  }

  .add-btn {
    margin-left: 0.5em;
    background-color: $primary-color;
    color: black;
    font-weight: 300;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 30px;
    line-height: 1;
    border: none;
    border-radius: 0;
    padding: 0;
    width: 34px;
  }
  .quantity-text,
  .price-text {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px;
    color: $black-text-color;
  }
  .quantity-text{
    margin-right:1rem;
  }
  .strikethough-text{
    text-align:"left";
    text-decoration:"line-through";
    margin-right:30px;
    margin-left: 1em;
    color:$light-grey;
  }

}

#sides-modal-popup{
  .modal-content {
    max-width: 420px !important;
    margin: 30px auto !important;
  }
  .modal-body {
    padding: 0 !important;
  
  }

  #google-play-btn {
    bottom: 110px;
    img {
      width: 160px;
      height: auto;
    }
  }
}

@media (min-width: 992px) {
  .meal-image {
    height: 16vw;
  }
}

@media(max-width: 768px) {
  .meal-image {
    height: 26vw;
  }
}

@media(max-width: 992px) {
  .meal-image {
    height: 26vw;
  }
}

@media(max-width: 540px) {
  .meal-image {
    height: 36vw;
  }
}

.separator {
  width:100%;
  height: 1px;
  background-color: $lighter-grey;
  margin: 1em 0;
}
.quantity-container {
  display: flex;
  align-items: center;

  .text {
    margin-right: 1em;
  }
  .btn {
    margin-right: 1em;
    border-radius: $border-radius;
    background-color: $primary-color;
    color: black;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 14px;
    border: none;
    &:last-child{
      margin-right:0;
    }
  }
}

@media(max-width: 540px) {
  .modal-content {

    .quantity-container {
      padding-left: 1em;
    }

  }

}

.meal-card.panel {
  .bottom-content-container{
    &.mod-catering{
      width:100%;
      display:block;
    }
  }
  .catering-card-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:0 1rem 1rem;
  }
}