@import "../../variables.scss";

.loader {
  border-radius: 50%;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
  &.big {
    border: 16px solid #f3f3f3;
    border-top: 16px solid $primary-color;
    height: 120px;
    width: 120px;
  }
  &.medium {
    border: 10px solid #f3f3f3;
    border-top: 10px solid $primary-color;
    height: 80px;
    width: 80px;
  }

  &.small {
    border: 8px solid #f3f3f3;
    border-top: 8px solid $primary-color;
    height: 60px;
    width: 60px;
  }
  &.extra-small {
    border: 4px solid #f3f3f3;
    border-top: 4px solid $primary-color;
    height: 30px;
    width: 30px;
  }
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}