@import "../../variables.scss";

.faq-container {
  font-family: 'Josefin Sans', sans-serif;
  width:100%
}
.faq-banner{
  // margin-top: 30px;
  width:100%;
  text-align:center;
  padding-top:40px;
  padding-bottom:40px;
  background-color: $primary-color;
  color:#fff;
  font-size:45px;
}
.faqs {
  text-align: center;

  margin-right: 10%;
  margin-left:10%
}
.center-align-text{
  text-align: center;
  margin-top:20px;
}