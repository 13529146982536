@import "../../../variables.scss";

#login-container {
  font-family: 'Josefin Sans', sans-serif;
  width: 100%;

  .container {
    width: 100%;
    padding: 0;
  }

  .title {
    color: black;
    font-size: 2.1em;
    margin-bottom: 1em;
    font-weight: bold;
    text-align: center;
  }

  .desc{
    color: black;
    font-size: 1.3em;
    margin:2rem 0;
    font-weight: bold;
    text-align: center;
  }

  input {
    display: block;
    border-radius: 0;
    border: 0.5px solid $lighter-grey;
    width: 100%;
    margin-bottom: 15px;
    padding: 25px;
    color: $matterhorn;
  }

  .next-btn {
    padding: 0.5em 4em;
    border: none;
    border-radius: $border-radius;
    background-color: $primary-color;
    color: black;
    height: 52px;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px;
    width: 100%;

    img {
      margin-left: 1em;
    }
  }

  .join-us {
    color: $light-grey;
    margin-top: 1em;
  }

  .forgot-pwd-btn {
    float: left;
    margin-left: -8px;
    color: $matterhorn;
    font-weight: bold;
    margin-bottom: 24px;
    clear:both;
  }

  .sign-up-btn {
    color: $matterhorn ;
    font-weight: bold;
  }

  .error-message {
    color: red;
    margin-bottom: .5rem;
    font-size: 1rem;
    float: left;
  }
}