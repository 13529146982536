@import "../../variables.scss";

.banner-bg {
  background-image: url('/img/promotion-banner.png');
  background-repeat: no-repeat;
  width: 100%;
  height: 100px;
  background-color: rgb(240, 240, 242);
}

.promotion-banner-content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;

  .promotion-banner-content {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .promotion-text {
    font-family: 'Josefin Sans', sans-serif;
  }
  .subtitle {
    font-size: 14px;
    color: $black-text-color;
  }
  .title {
    font-size: 16px;
    color: $black-text-color;
    font-weight: 600;
  }
  .highlighted-title {
    font-size: 16px;
    color: $primary-color;
    font-weight: 600;
  }
}